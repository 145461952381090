import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const container = {
  hidden: {
    opacity: 0,
    top: 0,
    transition: {
      staggerChildren: 1,
      delayChildren: 1,
      // ease: "easeOut",
      duration: 0.5,
    },
  },
  show: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.3,
      // ease: "easeOut",
      duration: 0.2,
    },
    opacity: 1,
    top: 59,
  },
};

const item = {
  hidden: { opacity: 0, y: 0 },
  show: { opacity: 1, y: 1 },
};

const ManualSignInForm = ({ handleSignIn, setIsResetPasswordMode }) => {
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    handleSignIn(data, "manualSignIn");
  };

  const handleShowPassword = (state) => {
    if (state === "show") {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className="w-full flex flex-col items-center space-y-6 px-2"
      >
        <div className="w-full">
          <input
            placeholder="Email"
            {...register("email")}
            type="text"
            required
            style={{ overflowY: "hidden", resize: "none" }}
            className="h-13 py-3 w-full border-b-[0.09rem] border-solid border-darkgrey focus:border-[#0d6efd] ease-in duration-200 outline-none bg-transparent"
          />

          {errors.email && (
            <div className="error-text text-primary-dark sm:mt-2 text-xs mb-8">
              {errors.email.message}
            </div>
          )}
        </div>
        <div className="relative w-full">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            {...register("password")}
            required
            style={{ overflowY: "hidden", resize: "none" }}
            className="h-13 py-3 w-full border-b-[0.09rem] border-solid border-darkgrey focus:border-[#0d6efd] ease-in duration-200 outline-none bg-transparent"
          />
          {/* <i className="las la-eye la-lg text-[#4BB543] " /> */}

          {showPassword ? (
            <i
              className="las la-eye-slash la-lg absolute top-4 right-0 z-[12] cursor-pointer"
              onClick={() => handleShowPassword("hide")}
            />
          ) : (
            <i
              className="las la-eye la-lg absolute top-4 right-0 z-[12] cursor-pointer"
              onClick={() => handleShowPassword("show")}
            />
          )}
          {errors.password && (
            <div className="error-text text-primary-dark sm:mt-2 text-xs">
              {errors.password.message}
            </div>
          )}
        </div>

        <div className="action-btns w-full">
          <button
            type="submit"
            className="w-full px-4 py-3 rounded-full bg-primary-light font-bold text-white hover:bg-primary-dark ease-in duration-300 shadow-lg active:bg-darkgrey"
          >
            Login
          </button>
        </div>

        <span
          className="text-sm self-center cursor-pointer block"
          onClick={() => setIsResetPasswordMode(true)}
        >
          Forgot Password?{" "}
          <span className="text-primary-light font-bold cursor-pointer hover:underline">
            Reset
          </span>
        </span>


      </motion.div>
    </form>
  );
};

export default ManualSignInForm;
