import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import UserDetails from "../components/custom/UserDetails";

const container = {
  show: {
    transition: {
      staggerChildren: 5,
      ease: "easeOut",
      duration: 10,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: 100 },
  show: { opacity: 1, y: 0 },
};

const ProfilePage = () => {
  return (
    <div className="bg-lightgrey max-h-full w-full flex flex-col items-center overflow-hidden pb-8">
      <div className="header items-center flex flex-col m-6 text-primary-light text-4xl font-bold h-full">
        <div className="heading">Edit Profile</div>
      </div>
      <div className="content flex xl:w-[50%] md:w-[80%] w-full h-auto md:p-8 p-2 justify-center overflow-hidden mb-8">
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="white-box"
        >
          <motion.div variants={item}>
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <UserDetails popupReason={''}/>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default ProfilePage;
