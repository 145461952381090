import React from "react";
import { useNavigate } from "react-router";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="pt-4 pb-4 flex flex-col justify-start items-center px-5">
      <div>
        <ul className="social-links flex items-center my-2">
          <li className="text-primary-light mr-3">
            <a target="_blank" href="https://www.facebook.com/people/Opinione/100094181353887" className="border border-indigo-600">
              <i className="lab la-facebook-f"></i>
            </a>
          </li>
          <li className="text-primary-light mr-3">
            <a target="_blank" href="https://twitter.com/Opinionedotorg">
              <i className="lab la-twitter"></i>
            </a>
          </li>
          <li className="text-primary-light mr-3">
            <a target="_blank" href="https://youtube.com/@opinionedotorg">
              <i className="lab la-youtube"></i>
            </a>
          </li>
          <li className="text-primary-light mr-3">
            <a target="_blank" href="https://www.linkedin.com/in/opinione-org-9954a4282">
              <i className="lab la-linkedin-in"></i>
            </a>
          </li>
          <li className="text-primary-light mr-3">
            <a target="_blank" href="https://www.instagram.com/opinione_hq">
              <i className="lab la-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="flex flex-col items-center space-y-3">
        <div className="flex flex-wrap justify-center w-full">
          Copyright © {(new Date().getFullYear())}
          <span className="text-primary-light mx-2">Opinione</span> All rights
          reserved.
        </div>
        <div className="flex flex-wrap justify-center w-full">
          Made with <i className="las la-heart la-lg text-[#EC2E20] mx-1 pt-1" /> by <span className="text-primary-light mx-1">Onesoft</span>  
           
        </div>
        <div className="flex flex-wrap items-center space-x-3 w-full text-sm copyright_links justify-center">
          <span
            className="text-primary-light hover:text-primary-dark cursor-pointer">
            <a href="mailto:hello@opinione.org">Contact</a> 
          </span>
          <span className="text-darkgrey">|</span>
          <span
            className="text-primary-light hover:text-primary-dark cursor-pointer"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy
          </span>
          <span className="text-darkgrey">|</span>
          <span
            className="text-primary-light hover:text-primary-dark cursor-pointer"
            onClick={() => navigate("/terms-and-conditions")}
          >
            Terms
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
