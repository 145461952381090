import React from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

const DoughnutChart = (props) => {
  const { chartData } = props;

  return <Doughnut data={chartData} 
  options={{
    cutout: '0%', borderWidth: 5}}/>;
};

export default DoughnutChart;
