import axiosInstance from "./axiosConfig";
import { toast } from "react-toastify";
class UserProfileService {
  updateDob = (value) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/user/updateProfile/dob`, { value })
        .then((response) => {
          if (response.data.success) {
            toast.success("Date Of Birth Updated", {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
            resolve(response.data);
          } else {
            toast.error("Failed To Update Birth Year", {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Update Birth Year", {
            autoClose: 3000, 
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  updateUsername = (value) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/user/updateProfile/username`, { value })
        .then((response) => {
          if (response.data.success) {
            toast.success("Username Updated", {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
            resolve(response.data);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  updateGender = (value) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/user/updateProfile/gender`, { value })
        .then((response) => {
          if (response.data.success) {
            toast.success("Gender Updated", {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
            resolve(response.data);
          } else {
            toast.error("Failed To Update Gender", {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Update Gender", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };
  updateEmail = (value) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/user/updateProfile/email`, { value })
        .then((response) => {
          if (response.data.success) {
            toast.error("Email Updated", {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
            resolve(response.data);
          } else {
            toast.error("Failed To Update Email", {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Update Email", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  getDetailsByUserId = (userId) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/user/profile?user=${userId}`)
        .then((response) => {
          resolve(response.data);
        })

        .catch((error) => {
          reject(error);
        });
    });
  };
}

const UserProfileInstance = new UserProfileService();
export default UserProfileInstance;
