import React, { useEffect, useState } from "react";
import "../../../../styles/popup.css";
import { createPortal } from "react-dom";

const modalRoot = document.getElementById("modal");

const UniversalPopup = ({ children, ...props }) => {
  const modalElement = document.createElement("div");
  const { handleClose } = props;
  const [transition, setTransition] = useState("in");

  useEffect(() => {
    modalRoot.appendChild(modalElement);
    return () => {
      modalRoot.removeChild(modalElement);
    };
  }, [modalElement]);

  const closePopup = () => {
    setTransition("out");
    setTimeout(() => {
      handleClose();
    }, 200);
  };

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      // Perform action only if the click occurred on the parent div
      closePopup();
      // Perform your action here
    }
  };

  return createPortal(
    <div
      className={`popup overflow-y-auto z-[12] h-full fade-${transition} ${
        transition === "in" ? "backdrop-blur-[1px] " : ""
      }`}
      onClick={handleClickOutside}
    >
      <div
        className={`popup_inner rounded-lg drop-shadow-xl fade-${transition} slide-${transition}`}
      >
        {props.title ? (
          <div className="flex justify-between items-end">
            <div className="text-primary-light font-semibold text-2xl mx-6">
              {props?.title}
            </div>
            <button className="close-btn" onClick={() => closePopup()}>
              <img
                className="m-4"
                src="/assets/images/close.png"
                alt="x close"
              />
            </button>
          </div>
        ) : (
          <div className="w-full flex justify-end">
            <button className="close-btn" onClick={() => closePopup()}>
              <img
                className="m-4"
                src="/assets/images/close.png"
                alt="x close"
              />
            </button>
          </div>
        )}
        <div className="content flex flex-col items-center justify-center h-[50%] w-full">
          {children}
        </div>
      </div>
    </div>,
    modalRoot
  );
};

export default UniversalPopup;
