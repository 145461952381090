import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router"; 
import PollServiceInstance from "../../../services/pollService";

const container = {
  hidden: {
    opacity: 0,
    top: 0,
    transition: {
      staggerChildren: 1,
      delayChildren: 1,
      // ease: "easeOut",
      duration: 0.5,
    },
  },
  show: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.3,
      // ease: "easeOut",
      duration: 0.5,
    },
    opacity: 1,
    top: 59,
  },
};

const item = {
  hidden: { opacity: 0, y: 0 },
  show: { opacity: 1, y: 1 },
};

const SearchBox = () => {
  const navigate = useNavigate(); 
  const [searchText, setSearchText] = useState("");
  const [searchDropdownList, setSearchDropdownList] = useState([]);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [typedString, setTypedString] = useState(""); 
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
  const [pickedLetters, setPickedLetters] = useState('');
  const [sentences, setSentences] = useState([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Example breakpoint for mobile screens

  useEffect(() => {
    // Function to update isMobile state when the window is resized
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Update the breakpoint as needed
    };

    // Attach the event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (sentences.length === 0) { 
      return; // No sentences to loop through
    }

    const currentSentence = sentences[currentSentenceIndex];
    const timerId = setInterval(() => {
      if (currentLetterIndex < currentSentence.length) {
        const nextLetter = currentSentence[currentLetterIndex];
        setPickedLetters((prevLetters) => {
          // Add the new letter to the end of the string
          const newLetters = prevLetters + nextLetter;
    
          // If the new string length exceeds the maximum, remove letters from the start
          if(isMobile) { 
            if (newLetters.length > 35) {
              return newLetters.substring(newLetters.length - 35);
            }
          }
    
          return newLetters;
        });
    
        setCurrentLetterIndex((prevIndex) => prevIndex + 1);
         
      } else {
        clearInterval(timerId); // Stop the interval when all letters are picked
        setCurrentLetterIndex(0); // Reset letter index
        setCurrentSentenceIndex((prevIndex) => (prevIndex + 1) % sentences.length); // Move to the next sentence
        setPickedLetters(''); // Clear the picked letters
      }
    }, 100); // Set interval to 1 second

    return () => {
      clearInterval(timerId); // Cleanup on unmount
    };
  }, [currentSentenceIndex, currentLetterIndex, sentences]);


  const searchPollWithDelay = (value) => {
    if (value.length && value.length !== " ") {
      setTypedString(value);
      PollServiceInstance.searchPoll(value)
        .then((response) => {
          setSearchDropdownList(response);
          setOpenDropDown(true);
        })
        .catch(); 
    } else {
      setSearchDropdownList([]);
      setOpenDropDown(false);
    }
  };


  useEffect(() => {
    let timerId;

    if (typedString) {
      // Clear any existing timer
      clearTimeout(timerId);

      // Set a new timer to execute the search after 3 seconds
      timerId = setTimeout(() => {
        searchPollWithDelay(typedString);
      }, 1000); // 3000 milliseconds = 3 seconds
    }

    // Cleanup: Clear the timer when the component unmounts or when 'typedString' changes
    return () => {
      clearTimeout(timerId);
    };
  }, [typedString]);

  useEffect(() => { 
    PollServiceInstance.getMostVotedPolls()
        .then((response) => { 
          setSentences(response.data.map(poll => poll.poll)); 
        })
        .catch( (err) => { 
          setSentences([
            'Which language should I learn first, German or Japanese?',
            'Do you like electric vehicle or petrol vehicle?',
            'Have you ever been cheated?',
            'If the world was going to end tomorrow, you would blow all your savings on?',
            'What age you are thinking of marrying at?'
          ])
  }); 
  }, []);


  const handleSearch = (event) => {
    event.preventDefault();
    const inputValue = event.target.value.trim();

    if (inputValue.length) {
      setTypedString(inputValue);
    } else {
      setTypedString('');
      setSearchDropdownList([]);
      setOpenDropDown(false);
    }
  };
   
  const handleResetSearch = (event) => {
    event.preventDefault();
    setTimeout(() => {
      setSearchText("");
      handleSubmit("");
    }, 200);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchDropdownList.length) {
      navigate(`/poll/${searchDropdownList[0].slug}`);
    }
  };

  const renderDropDownElements = (listElement) => {
    const indexOfSearchedWord = listElement.split(" ").forEach((eachWord) => {
      return eachWord.includes(typedString) ? eachWord.indexOf(typedString) : 0;
    });

    const filteredWord = listElement.substring(
      indexOfSearchedWord,
      typedString.length
    );
    if (filteredWord.toUpperCase() === typedString.toUpperCase()) {
      return (
        <div>
          <span className="font-bold">{filteredWord}</span>
          {`${listElement.substring(typedString.length)}`}
        </div>
      );
    }
    return listElement;
  };

  return (
    <form
      autoComplete="off"
      className="flex justify-center w-full"
      onSubmit={handleSubmit}
      onReset={handleResetSearch}
    >
      <div className="relative flex items-center justify-center w-full sm:w-[60%] mx-4">
        <input
          onBlur={() => {
            setTimeout(() => {
              setOpenDropDown(false);
            }, 300);
          }}
          className=" p-4 pr-12 rounded-xl border-[1px] border-darkgrey border-solid w-full focus:outline-0 w-full focus:shadow-md z-[11]"
          id="myInput"
          placeholder={pickedLetters + "_"} 
          itemID="autoresult"
          onChange={(event) => handleSearch(event)}
        />
        {openDropDown && (
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className="drop-down absolute w-[96%] h-auto bg-white shadow-xl z-[10] flex flex-col  rounded-lg"
          >
            {searchDropdownList.map((Listitem, index) => (
              <motion.div
                variants={item}
                key={index}
                initial="hidden"
                animate="show"
                onClick={() => navigate(`/poll/${Listitem.slug}`)}
                className="w-full hover:font-semibold cursor-pointer hover:bg-[#dac9c98b] h-full p-4 rounded-lg"
              >
                {renderDropDownElements(Listitem.poll)}
              </motion.div>
            ))}
          </motion.div>
        )}
        <div className="absolute top-[0.25rem] right-[0.3rem] flex items-center">
          {searchText.length > 0 && (
            <button
              type="reset"
              className="mr-12 mt-[0.1rem] slide-out-left z-[13]"
            >
              <img
                src="/assets/images/close.png"
                alt="clear"
                title=""
                className="w-10/12 opacity-50"
              />
            </button>
          )}
          <div
            // type="submit"
            className="py-2 px-4 rounded-lg z-[12] cursor-pointer"
          >
            <i className="las la-search text-primary-light text-2xl"></i>
            {/* <img
              src="/assets/images/search-icon.svg"
              alt="search icon"
              title=""
              className=""
            /> */}
          </div>
        </div>
      </div>
    </form>
  );
};

export default SearchBox;
