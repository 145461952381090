import moment from "moment";
import React, { useEffect } from "react";

const DataDeletionPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-4xl font-bold mb-4">Data Deletion Policy</h2>
      <p className="mb-4">Effective Date: 1 July 2023</p>
      <p className="mb-4">
        At Opinione, we understand the importance of data privacy and provide
        mechanisms for users to request the deletion of their personal
        information. This Data Deletion Policy outlines our procedures and
        guidelines for handling data deletion requests. By using our website and
        services, you agree to the terms and practices described in this policy.
      </p>
      <h3 className="text-lg font-bold mb-2">1. Data Deletion Requests:</h3>
      <div className="ml-4">
        <h4 className="text-base font-bold mb-2">
          1.1 Requesting Data Deletion:
        </h4>
        <ul className="list-disc ml-6 mb-4">
          <li>
            If you wish to have your personal information deleted from our
            systems, you can submit a data deletion request by contacting us
            using the provided contact information. Please include the details
            necessary to identify your data accurately.
          </li>
        </ul>
        <h4 className="text-base font-bold mb-2">1.2 Verification:</h4>
        <ul className="list-disc ml-6 mb-4">
          <li>
            To ensure the security and integrity of our users' data, we may
            require additional information to verify your identity before
            processing a data deletion request. This verification process helps
            protect your data from unauthorized access or deletion.
          </li>
        </ul>
        <h4 className="text-base font-bold mb-2">1.3 Third-Party Data:</h4>
        <ul className="list-disc ml-6 mb-4">
          <li>
            Please note that we can only delete the personal information that we
            directly control and process. If your data has been shared with or
            collected by third parties through our services, we will assist you
            in contacting those parties, but we cannot guarantee their
            compliance with your data deletion request.
          </li>
        </ul>
      </div>
      <h3 className="text-lg font-bold mb-2">2. Data Deletion Process:</h3>
      <div className="ml-4">
        <h4 className="text-base font-bold mb-2">2.1 Timelines:</h4>
        <ul className="list-disc ml-6 mb-4">
          <li>
            Once we receive a valid data deletion request and complete the
            necessary verification, we will make reasonable efforts to delete
            the requested data within a reasonable timeframe, as required by
            applicable laws and regulations.
          </li>
        </ul>
        <h4 className="text-base font-bold mb-2">2.2 Permanent Deletion:</h4>
        <ul className="list-disc ml-6 mb-4">
          <li>
            We will take appropriate measures to ensure the permanent deletion
            of your personal information from our active systems and backups,
            unless retention is necessary to comply with legal obligations or
            protect our legitimate interests.
          </li>
        </ul>
        <h4 className="text-base font-bold mb-2">2.3 Retained Data:</h4>
        <ul className="list-disc ml-6 mb-4">
          <li>
            In some cases, we may be required to retain certain information even
            after fulfilling your data deletion request, such as for legal,
            accounting, or security purposes. Any retained data will be
            protected in accordance with this Data Deletion Policy.
          </li>
        </ul>
      </div>
      <h3 className="text-lg font-bold mb-2">
        3. Communication and Confirmation:
      </h3>
      <div className="ml-4">
        <h4 className="text-base font-bold mb-2">3.1 Confirmation:</h4>
        <ul className="list-disc ml-6 mb-4">
          <li>
            Upon completion of your data deletion request, we will notify you
            through the contact information provided to confirm the deletion of
            your personal information from our systems, unless prohibited by law
            or regulation.
          </li>
        </ul>
        <h4 className="text-base font-bold mb-2">
          3.2 Retention of Confirmation:
        </h4>
        <ul className="list-disc ml-6 mb-4">
          <li>
            We may retain a record of your data deletion request and our
            confirmation for internal documentation and compliance purposes.
          </li>
        </ul>
      </div>
      <h3 className="text-lg font-bold mb-2">
        4. Changes to the Data Deletion Policy:
      </h3>
      <div className="ml-4 mb-4">
        <p>
          We may update or modify this Data Deletion Policy from time to time.
          Any changes will be posted on this page, and the revised version will
          be effective as of the "Effective Date" stated above. We encourage you
          to review this policy periodically for any updates.
        </p>
      </div>
      <h3 className="text-lg font-bold mb-2">5. Contact Us:</h3>
      <div className="ml-4 mb-4">
        <p>
          If you have any questions or concerns regarding this Data Deletion
          Policy or our data practices, please contact us using the provided
          contact information.
        </p>
      </div>
      <p className="mt-4">
        Please note that this Data Deletion Policy applies to personal
        information collected and processed by Opinione. If you have provided
        your personal information to third parties or other websites, their data
        deletion policies and practices will govern the handling of your data.
      </p>
    </div>
  );
};

export default DataDeletionPolicy;
