import axiosInstance from "./axiosConfig";
import { toast } from "react-toastify";

class CategoryService {
  getAllCategories = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/admin/getPollCategory`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Get Categories", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };
}

const CategoryServiceInstance = new CategoryService();
export default CategoryServiceInstance;
