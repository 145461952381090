
class PollHelper {

    addFakeData = true;
    
    fakeNumberOfvotesOnPollCard = (createdAt) => {
    if(this.addFakeData)
    return ((Math.round(Math.abs((new Date(createdAt) - (new Date())) / (24 * 60 * 60 * 1000)))) * 12);
    else
    return 0;
    };  
    
    
    fakeNumberOfLikesOnPollCard = (createdAt) => {
    if(this.addFakeData)
    return ((Math.round(Math.abs((new Date(createdAt) - (new Date())) / (24 * 60 * 60 * 1000)))) * 2);
    else
    return 0;
    };  
 
    fakeNumberOfvotesOnCharts = (createdAt,numberOfNonZero,optionId,pollOptionsLength,index) => { 
    if(this.addFakeData) { 
    let value = 0;
    if(index+2%2) {
    value = ((Math.round(Math.abs(((new Date(createdAt)) - (new Date())) / (24 * 60 * 60 * 1000)))) * Math.ceil(12/numberOfNonZero/(optionId ? pollOptionsLength : 1)));
    } else {    
    value = ((Math.round(Math.abs(((new Date(createdAt)) - (new Date())) / (24 * 60 * 60 * 1000)))) * Math.floor(12/numberOfNonZero/(optionId ? pollOptionsLength : 1)));
    }
    return value; 
    }
    else
    return 0;
    };  

}

const PollHelperInstance = new PollHelper();
export default PollHelperInstance;
 