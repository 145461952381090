import { motion } from "framer-motion";
import React, { useContext, useEffect, useState, useRef } from "react"; 
import NewsletterSection from "../components/footer/newsletterSection"; 
import Footer from "../components/footer/footer";
import { RotatingSquare } from "react-loader-spinner";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { UserContext } from "../../App";
import AuthServiceInstance from "../../services/authService";
import CategoryServiceInstance from "../../services/categoriesService";
import PollServiceInstance from "../../services/pollService";
import Button from "../components/buttons/Button";
import PollCard from "../components/cards/pollCard";
import SearchBox from "../components/fields/searchBox";  
import { useNavigate } from "react-router";
import { container } from "../../utils/motionDiv";
 
const HomePage = () => { 
  const navigate = useNavigate(); 
  const [pollsFetched, setPollsFetched] = useState([]);
  const [showShowMore, setShowShowMore] = useState(true);
  const [isPollsLoading, setIsPollsLoading] = useState(true);
  const [allCategories, setAllCategories] = useState([]);
  const [pollPageNumber, setPollPageNumber] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [categoryId, setCategoryId] = useState('');
  const [oldCategoryId, setOldCategoryId] = useState('');
  const {
    state: { isLoggedIn },
  } = useContext(UserContext);
  
  const pageSize = 10; //20 polls will be fteched per page 
  const elementRef = useRef(null); 
  const [leftArrowDisable, setLeftArrowDisable] = useState(false);
  const [rightArrowDisable, setRightArrowDisable] = useState(false);
  const handleHorizontalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0; 
    const slideTimer = setInterval(() => {
      if(element)
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) { 
        clearInterval(slideTimer);
      }
      // if (element.scrollLeft === 0) {
      //   setLeftArrowDisable(true);
      // } else {
      //   setLeftArrowDisable(false);
      // }
 
      // console.log(element.offsetWidth)
      // if (element.scrollRight === 0) {
      //   setRightArrowDisable(true);
      // } else {
      //   setRightArrowDisable(false);
      // }


    }, speed);
  };

  //todo: apply length check

  /* const handelInfiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.scrollHeight
    ) {
      setPollPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }; */ 
 

  const loadMore = () => { 
      setPollPageNumber((prevPageNumber) => prevPageNumber + 1); 
  };

  useEffect(() => {
    if (pollPageNumber > 0) {
      const fetchedPollDataOnScroll = fetchPollData(categoryId);
      if (fetchedPollDataOnScroll?.length)
        window.scrollTo({ top: document.documentElement.scrollTop });
    }
  }, [pollPageNumber]);

  useEffect(() => {

    setTimeout(() => {
      const categoryScroll = document.getElementById("categoryScroll");
      handleHorizontalScroll(categoryScroll, 10, 50, 10);
    }, 1000);
    

    window.scrollTo({ top: 0 });
    fetchPollData();

    CategoryServiceInstance.getAllCategories()
      .then((response) => {
        setAllCategories([{_id:'', name: 'Trending'}].concat(response));
        
      })
      .catch();
  }, []);

  useEffect(() => {
    fetchMe();
  }, [isLoggedIn]);

  const fetchMe = async () => {
    const user = await AuthServiceInstance.me(); 
    if (user?.success) {
      setUserInfo(user.data);
    }
  };

  const fetchPollData = (categoryId = '') => { 
    let localPollPageNumber = pollPageNumber;
    if(oldCategoryId !== categoryId) {
      localPollPageNumber = 0;
      setPollPageNumber(0);
    }  
    PollServiceInstance.getAllPolls(pageSize, localPollPageNumber, categoryId)
      .then((response) => { 
        if(response?.data?.length < 10)
        setShowShowMore(false);
        else  
        setShowShowMore(true);

        if (response.success) { 
          if(oldCategoryId !== categoryId) {  
            setPollsFetched([]);
            setTimeout(() => { 
              setPollsFetched(response.data); 
            }, 100); 
            setOldCategoryId(categoryId); 
          } else {
            setPollsFetched((prev) => [...new Set([...prev, ...response.data])]); 
          }
          
          return response?.data;
        }
      })
      .catch(() => {
        return null;
      })
      .finally(() => setIsPollsLoading(false));
  };

  /* useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []); */
 
  const handleCategoryFilter = async (categoryId) => {
    setIsPollsLoading(true);
    setCategoryId(categoryId);  
    fetchPollData(categoryId);
  };

  return (
    <div className="main w-full">
      <div className="flex justify-center w-full">
        <h1 className="mt-6 text-primary-light text-center xl:text-[2.5rem] md:text-3xl text-[20px] font-medium px-4">
        Discover a treasure collection of exclusive polls
        </h1> 
         
      </div>
      <div className="flex flex-col items-center w-full">
        <div className="my-6 w-full">
          <SearchBox pollsFetched={pollsFetched}/>
        </div>    
        </div>


         
        { allCategories?.length > 0 && (
        <div className="flex flex-row bg-white m-auto p-auto item-center justify-center w-[95%] md:w-[80%] lg:w-[80%]"> 
        <div className="inline pr-1">
        <button  
        className="mt-1" onClick={() => {
          handleHorizontalScroll(elementRef.current, 10, 500, -10);
          }}>   
          <img src="/assets/images/arrow-left.png" className="max-w-[20px] w-[20px] h-[20px]"/>   
        </button>
        </div>
        <div id="categoryScroll" ref={elementRef}
        className="inline flex overflow-x-scroll rounded-full hide-scroll-bar bg-lightgrey"> 
        <div className=" flex flex-nowrap">   
            {allCategories.map((category, index, allCategoriesArray) => ( 
            <div key={index} className="flex items-center w-[max-content]">
              <span
              className={"mx-2 cursor-pointer text-primary-light hover:text-primary-dark " + (categoryId === category._id ? 'font-bold' : '')} 
                onClick={() => handleCategoryFilter(category._id)}
              >
                {category.name}
              </span>
              <span className="text-primary-dark font-light">
                {index !== allCategoriesArray.length - 1 ? " | " : ""}
              </span>
            </div>
            )
            )
            } 
        </div> 
      </div>
      <div className="inline pl-1"> 
      <button 
      className="mt-1" onClick={() => {
        handleHorizontalScroll(elementRef.current, 10, 500, 10);
        }}>  
      <img src="/assets/images/arrow-right.png" className="max-w-[20px] w-[20px] h-[20px]"/>  
      </button>
      </div>
      </div>   
      )}
 

       
      <div className="flex flex-col items-center w-full bg-lightgrey mt-6 pt-3 overflow-x-hidden">
        {/* <div className=" flex items-center my-4 sm:w-2/3 w-[90%] overflow-x-auto">
          <CategoryScrollbar
            categories={allCategories}
            onCategoryClick={(categoryId) => handleCategoryFilter(categoryId)}
          />
        </div> */}
        <div className="poll-content w-full mt-4 lg:px-12 md:px-4 px-2 mb-8">
          {!isPollsLoading ? (
          pollsFetched.length ? ( 
            <motion.div  
            // variants={container()}
            // initial="hidden"
            // animate="show"
            className="">
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 700: 1, 780: 2, 1440: 3 }}
              >
                <Masonry gutter="2rem">
                  {pollsFetched.map((poll, index) => {
                    return (
                        <PollCard
                          key={index}
                          data={poll}
                          index={index}
                          showDetailButton  
                          userInfo={userInfo}
                          reFetchMe={fetchMe}
                        />
                    );
                  })}
                </Masonry>
              </ResponsiveMasonry>
            </motion.div>
          ) : (
            <div className="w-full h-[10vh] my-12 flex items-center justify-center">
            <span className="text-center text-primary-light text-xl font-bold antialiased">
              How about creating some exciting polls...
            </span>
          </div>
          ) ) : (
            <div className="w-full h-[10vh] my-12 flex items-center justify-center">
              <span className="text-primary-light text-xl font-bold antialiased">
                Loading...
              </span>
            </div>
          )} 
        </div>

        {isPollsLoading && (
            <div className="w-full min-h-max flex justify-center">
              <RotatingSquare
                height="300"
                width="300"
                color="#EE585D"
                ariaLabel="rotating-square-loading"
                strokeWidth="4"
                wrapperStyle={{}}
                wrapperclassName=""
                visible={true}
              />
            </div>
          )}

        {showShowMore ? (
        <div className="loadmore text-center mt-4 mb-4"> 
              <Button  
                variant="filled"
                title="Show More" 
                onClick={() => loadMore()}
              />
        </div>
        ) : (
          <div className="loadmore text-center mt-4 mb-4"> 
              <Button  
                variant="outlined"
                title="Create Poll +" 
                onClick={() => navigate('/create-poll')}
              />
        </div>
        )}
      </div> 
      <NewsletterSection /> 
      <Footer /> 
    </div>
  );
};

export default HomePage;
