import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
      <h2 className="text-xl mb-4">Effective Date: 1 July 2023</h2>

      <p className="mb-4">
        Thank you for choosing [Your Polling Website]. This Privacy Policy
        outlines how we collect, use, disclose, and protect the personal
        information of our users. By using our website and services, you agree
        to the terms and practices described in this policy.
      </p>

      <h3 className="text-lg font-bold mb-2">1. Information We Collect:</h3>

      <h4 className="text-lg font-bold mb-2">1.1 Personal Information:</h4>
      <p className="mb-2">
        - When you register on our website, we collect your name, email address,
        and any other information you provide during the registration process.
      </p>
      <p className="mb-2">
        - When you create a poll, we collect the data you input, such
        as questionnaires, options, and responses.
      </p>

      <h4 className="text-lg font-bold mb-2">1.2 Log Data:</h4>
      <p className="mb-2">
        - We automatically collect certain information when you access our
        website, including your IP address, browser type, operating system, and
        referring website.
      </p>

      <h4 className="text-lg font-bold mb-2">
        1.3 Cookies and Similar Technologies:
      </h4>
      <p className="mb-4">
        - We use cookies and similar technologies to enhance your experience,
        gather information about your usage patterns, and improve our services.
        You can manage your cookie preferences through your browser settings.
      </p>

      <h3 className="text-lg font-bold mb-2">2. Use of Information:</h3>

      <h4 className="text-lg font-bold mb-2">
        2.1 Provide and Improve Services:
      </h4>
      <p className="mb-4">
        - We use the collected information to provide, maintain, and improve our
        polling services, including analyzing and enhancing the functionality of
        our website.
      </p>

      <h4 className="text-lg font-bold mb-2">2.2 Communication:</h4>
      <p className="mb-4">
        - We may use your email address to send you administrative or
        promotional communications related to our services. You can opt out of
        promotional emails at any time.
      </p>

      <h4 className="text-lg font-bold mb-2">2.3 Data Analysis:</h4>
      <p className="mb-4">
        - We may aggregate and anonymize data to generate statistical insights
        and improve our services. Aggregated data does not personally identify
        you.
      </p>

      <h4 className="text-lg font-bold mb-2">2.4 Legal Compliance:</h4>
      <p className="mb-4">
        - We may process your information to comply with applicable laws,
        regulations, or legal obligations.
      </p>

      <h3 className="text-lg font-bold mb-2">
        3. Information Sharing and Disclosure:
      </h3>

      <h4 className="text-lg font-bold mb-2">
        3.1 Third-Party Service Providers:
      </h4>
      <p className="mb-4">
        - We may engage trusted third-party service providers to assist us in
        operating our website and services. These providers have access to your
        information only to perform tasks on our behalf and are obligated to
        maintain its confidentiality.
      </p>

      <h4 className="text-lg font-bold mb-2">3.2 Business Transfers:</h4>
      <p className="mb-4">
        - In the event of a merger, acquisition, or any other change of control,
        your information may be transferred to the acquiring entity. We will
        notify you via email or prominent notice on our website before any such
        transfer takes place.
      </p>

      <h4 className="text-lg font-bold mb-2">3.3 Legal Requirements:</h4>
      <p className="mb-4">
        - We may disclose your information if required to do so by law or in
        response to valid requests by public authorities.
      </p>

      <h3 className="text-lg font-bold mb-2">4. Data Security:</h3>

      <p className="mb-4">
        - We implement reasonable security measures to protect your personal
        information from unauthorized access, alteration, disclosure, or
        destruction. However, no method of transmission over the internet or
        electronic storage is 100% secure, and we cannot guarantee absolute
        security.
      </p>

      <h3 className="text-lg font-bold mb-2">5. Your Rights:</h3>

      <h4 className="text-lg font-bold mb-2">
        5.1 Access, Update, and Delete:
      </h4>
      <p className="mb-4">
        - You have the right to access, update, and delete your personal
        information held by us. You can do this by contacting us using the
        provided contact information.
      </p>

      <h4 className="text-lg font-bold mb-2">5.2 Opting out:</h4>
      <p className="mb-4">
        - You can opt out of receiving promotional emails by following the
        unsubscribe instructions included in each email or by contacting us.
      </p>

      <h3 className="text-lg font-bold mb-2">6. Children's Privacy:</h3>

      <p className="mb-4">
        - Our website and services do not have any age restrictions.
      </p>

      <h3 className="text-lg font-bold mb-2">
        7. Changes to this Privacy Policy:
      </h3>

      <p className="mb-4">
        - We may update this Privacy Policy from time to time. Any changes will
        be posted on this page, and the revised version will be effective as of
        the "Effective Date" stated above. We encourage you to review this
        Privacy Policy periodically for any updates.
      </p>

      <h3 className="text-lg font-bold mb-2">8. Contact Us:</h3>

      <p className="mb-4">
        - If you have any questions or concerns regarding this Privacy Policy or
        our data practices, please contact us using the provided contact
        information on our website.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
