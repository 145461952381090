export const MainReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case "showSignupPopup":
      return { ...state, showSignUp: action.payload };
    case "showResetPasswordPopup":
      return { ...state, showResetPassWord: action.payload };
    case "login":
      return { ...state, isLoggedIn: action.payload };
    case "profile_status":
      return { ...state, profileStatus: action.payload };
    default:
      break;
  }
};
