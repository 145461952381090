import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AuthServiceInstance from "../../services/authService";
import Loader from "../components/Loader/loader";

const PrivateRoutes = ({ children, ...props }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasUserName, setHasUserName] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMe = async () => {
      try {
        const response = await AuthServiceInstance.me();
        setHasUserName(!!response?.data?.username?.length);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMe();
    if (localStorage.getItem("token")?.length) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      // setOpenSignUpForm(true);
    }
  }, []);

  const handleGoToProfile = () => {
    const state = { error: true };
    navigate("/edit-profile", { state });
  };

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <>
      {isAuthenticated && hasUserName ? <>{children}</> : <>{children}</>}
      {/* {isAuthenticated && hasUserName ? <>{children}</> : handleGoToProfile()} */}
    </>
  );
};

export default PrivateRoutes;
