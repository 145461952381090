import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import AuthServiceInstance from "../../services/authService";
import { TailSpin } from "react-loader-spinner";

const VerifyNewsLetterEmailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [verificationMessageRecieved, setVerificationMessageRecieved] =
    useState("");

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const id = searchParams.get("id");
      const token = searchParams.get("token");
      verifyEmail(id, token);
    }
  }, [location.search]);

  const verifyEmail = async (id, token) => {
    let navToHome;
    setIsLoading(true);
    const response = await AuthServiceInstance.verifyNewsLetterEmail(id, token);
    setIsLoading(false);
    if (response) {
      setVerificationMessageRecieved(response.message);

      navToHome = setTimeout(() => {
        navigate("/");
      }, 2000);
    }

    return () => clearTimeout(navToHome);
  };

  return (
    <div
      className="h-[90vh] w-full flex flex-col items-center justify-center bg-lightgrey"
      style={{
        background:
          "linear-gradient(0deg,  rgb(238, 88, 93) 0%, rgb(238, 88, 93) 0%)",
      }}
    >
      <motion.div
        whileHover={{ scale: 1.25 }}
        className="bg-lightgrey md:w-1/2 w-full rounded-full shadow-2xl shadow-black h-1/2 flex flex-col items-center justify-center "
      >
        {!isLoading ? (
          <span className="text-primary-dark text-2xl">
            {verificationMessageRecieved}
          </span>
        ) : (
          <TailSpin
            height="80"
            width="80"
            color="#EE585D"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
          />
        )}
      </motion.div>
    </div>
  );
};

export default VerifyNewsLetterEmailPage;
