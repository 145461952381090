import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router";
import AuthServiceInstance from "../../services/authService";
import PollServiceInstance from "../../services/pollService";
import UserProfileInstance from "../../services/userProfileService";
import Button from "../components/buttons/Button";
import PollCard from "../components/cards/pollCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import UniversalPopup from "../components/modals/UniversalPopup";
import { UserContext } from "../../App";
import { capitalizeFirstLetter } from "../../utils/formatters";
import { container } from "../../utils/motionDiv";
 
const s3Url = process.env.REACT_APP_S3_CDN_URL;
 
const item = {
  hidden: { y: 100, opacity: 0 },
  show: { opacity: 1, y: 0 },
};

const ArchivePage = () => { 
  const [showShowMore, setShowShowMore] = useState(true);
  const userContext = useContext(UserContext);
  const [pollsFetched, setPollsFetched] = useState([]);
  const [userFetched, setUserFetched] = useState(null);
  const [postedPollsCount, setpostedPollsCount] = useState(null);
  const [votedPollsCount, setvotedPollsCount] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isPollLoading, setIsPollLoading] = useState(true);
  const [isSignedInUser, setIsSignedInUser] = useState(false);
  const [tabValue, setTabValue] = useState("my_polls");
  const [oldTabValue, setOldTabValue] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [logoutConfirmationPopup, setLogoutConfirmationPopup] = useState(false);
  const [userId, setuserId] = useState(""); 
  const [pollPageNumber, setPollPageNumber] = useState(0);
  const param = useParams();
  const pageSize = 10;
  const navigate = useNavigate(); 

  useEffect(() => {
    setuserId(param?.id);
    setPollsFetched([]);
  }, [param?.id]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    userContext.logInUser(false);
    navigate("/");
  };

  const fetchMe = async () => {
    const me = await AuthServiceInstance.me();
    if (me.success) {
      setUserInfo(me.data);
      return me.data;
    }
  };
 
  useEffect(() => {
    if (pollPageNumber > 0) {
      if (tabValue === "my_polls") {
        getMyPolls(tabValue);
      }
      if (tabValue === "my_votes") {
        getMyVotes(tabValue);
      }
      // const fetchedPollDataOnScroll = fetchPollData(categoryId);
      // if (fetchedPollDataOnScroll?.length)
      //   window.scrollTo({ top: document.documentElement.scrollTop });
    }
  }, [pollPageNumber]);

  const loadMore = () => {
    setPollPageNumber((prevPageNumber) => prevPageNumber + 1); 
  };
  
  useEffect(() => {
    fetchMe();
  }, [userId]);

  const checkForSignedInUser = async (recievedUser) => {
    const me = await fetchMe();
    if (me._id === recievedUser._id) {
      setIsSignedInUser(true);
    } else {
      setIsSignedInUser(false);
    }
  };

  const getMyVotes = async (tabValue) => {
    setIsPollLoading(true);
    let localPollPageNumber = pollPageNumber; 
    if(oldTabValue !== tabValue) {
      localPollPageNumber = 0;
      setPollPageNumber(0);
    }  
    const response = await PollServiceInstance.getPollsWithMyVote(userId, pageSize, localPollPageNumber);         
    if (response.success) {
      
      if(response?.data?.length < 10)
      setShowShowMore(false);
      else  
      setShowShowMore(true);

      if(oldTabValue !== tabValue) {  
        setPollsFetched([]);
        setTimeout(() => { 
          setPollsFetched(
            response.data.map((data) => ({
              ...data.poll,
              voted: data.option,
            }))
          );
        }, 100); 
        setOldTabValue(tabValue); 
      } else { 
        setPollsFetched( 
          (prev) => [...new Set([...prev, ...response.data.map((data) => ({
            ...data.poll,
            voted: data.option,
          }))])] 
        );
      }
 
      setIsPollLoading(false);
    }
  };

  const getMyPolls = async (tabValue) => {
    let localPollPageNumber = pollPageNumber; 
    if(oldTabValue !== tabValue) {
      localPollPageNumber = 0;
      setPollPageNumber(0);
    }  
    setIsPollLoading(true);
    if (userId?.length) {
      const response = await PollServiceInstance.getPollsByUser(userId, pageSize, localPollPageNumber); 
      if (response) { 

        
        if(response?.data?.length < 10)
        setShowShowMore(false);
        else  
        setShowShowMore(true);
      
        if(oldTabValue !== tabValue) {  
          setPollsFetched([]);
          setTimeout(() => {  
          setPollsFetched(response.data);
          }, 100); 
          setOldTabValue(tabValue); 
        } else {  
        
          setPollsFetched((prev) => [...new Set([...prev, ...response.data])]);
        }
        
        setIsPollLoading(false);
      }
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0 });

    if (userId?.length) {
      setPollsFetched([]);
      getMyPolls(tabValue);

      UserProfileInstance.getDetailsByUserId(userId)
        .then((response) => {
          if (response.success) {
            setUserFetched(response.data);
            checkForSignedInUser(response.data);
          }
        })
        .catch()
        .finally(() => {
          setIsUserLoading(false);
        });
      
      PollServiceInstance.getVoteCount(userId)
        .then((response) => {
          if (response?.data?.voteCount) {
            setvotedPollsCount(response?.data?.voteCount); 
            setpostedPollsCount(response?.data?.pollCount); 
          }
        })
        .catch()
        .finally(() => {
          setIsUserLoading(false);
        }); 
    }
  }, [userId]);

  const handleTabChange = (newTab) => {
    setPollsFetched([]);
    setTabValue(newTab);
     
    if (newTab === "my_polls") {
      getMyPolls(newTab);
    }
    if (newTab === "my_votes") {
      getMyVotes(newTab);
    } 
  };
 

  return (
    <div className="page h-full w-full flex flex-col items-center">
      
      <div className="pb-10 w-full">
        <div className="header items-center flex flex-col m-12 text-primary-light text-4xl font-bold">
          <div className="heading">User Profile</div>
        </div>

        {userFetched === null && (<div style={{ height: '250px', width: 'auto' }}></div>)}
      
      <div className="relative content flex flex-col w-full justify-center items-start sm:p-0 p-1 rounded-lg drop-shadow-xl sm:w-full">
        
        {!isUserLoading ? ( 
          <>
            {userFetched !== null ? (
              <div className="user-info flex items-center justify-center sm:flex-row flex-col sm:space-x-12 sm:space-y-0 space-y-6 w-full ">
                <img
                  alt="profile"
                  src={
                    userFetched?.picture !== null
                      ? `${s3Url}/${userFetched?.picture}`
                      : "/assets/images/dummyUser.png"
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;  
                    currentTarget.src="/assets/images/dummyUser.png";
                  }} 
                  className="w-[5rem] h-[5rem] rounded-full"
                />
                <div className="flex items-start sm:gap-x-12 justify-around md:w-1/5 sm:w-1/2 w-full">
                  <div className="text-base">
                    <div className="fullname text-primary-light">Username</div>
                    <div className="font-semibold text-secondary text-lg">
                      {userFetched && userFetched?.username ? '@' + userFetched.username : 'Anonymous'}
                    </div>
                  </div>
                  {isSignedInUser && (
                  <div className="text-base">
                    <div className="gender text-primary-light">Gender</div>
                    <div className="font-semibold text-secondary text-lg">
                      {userFetched && userFetched?.gender ? capitalizeFirstLetter(userFetched.gender) : ''}
                    </div>
                  </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="h-[100vh] w-full flex flex-col space-y-4 items-center justify-center bg-lightgrey">
                <p className="text-6xl font-bold text-primary-light drop-shadow-lg">
                  Oop!
                </p>
                <span className="text-primary-dark text-2xl">
                  User Not Found !
                </span>
                <Button
                  title="Go back to Home"
                  variant="filled"
                  onClick={() => navigate("/")}
                />
              </div>
            )}
            {isSignedInUser && (
          <div className="flex items-center justify-center mx-auto mt-8 space-x-8">
            <Button
              variant="filled"
              title={
                <span className="flex items-center gap-x-3"> 
                  <span ><i className="las la-user-edit"></i> Profile</span>
                </span>
              }
              onClick={() => navigate(`/edit-profile?user=${userFetched?._id}`)}
            />
            <Button
              variant="outlined"
              title={
                <span>
                  <i className="las la-sign-out-alt" /> Logout 
                </span>
              }
              onClick={() => setLogoutConfirmationPopup(true)}
            />
          </div>
        )} 
          </>
        ) : (
          <div className="w-full flex justify-center space-x-6 ">
            <Oval
              height={80}
              width={80}
              color="#DB171E"
              wrapperStyle={{}}
              wrapperclassName=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#EE585D"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )} 
        

      </div>
      </div>
      {userFetched !== null && (
          <div className="content w-full flex flex-col items-center bg-lightgrey py-10">
            <div className="w-full tabs flex items-start space-x-6 justify-center">
              <button
                className="flex flex-col"
                onClick={() => handleTabChange("my_polls")}
              >
                
                <div className="text-2xl font-semibold">
                <span className="pr-2"> 
                  Post 
                </span>
                { postedPollsCount > 0 && 
                <span className="count_number">{postedPollsCount}</span>
                } 
                </div>
                {tabValue === "my_polls" && (
                  <div className="divider border-b-[0.2rem] border-solid border-primary-light w-[60%] my-2" />
                )}
              </button>
              <div className="border-r-2 border-darkgrey border-solid h-[2rem]" />
              <button
                className="flex flex-col"
                onClick={() => handleTabChange("my_votes")}
              >
                <div className="text-2xl font-semibold"> 
                <span className="pr-2">Vote</span>
                { votedPollsCount > 0 && 
                <span className="count_number">{votedPollsCount}</span>
                }
                </div>
                {tabValue === "my_votes" && (
                  <div className="divider border-b-[0.2rem] border-solid border-primary-light w-[60%] my-2" />
                )}
              </button>
            </div>
            <div className="w-full mt-8 container mx-auto">
              {pollsFetched.length ? (
                <motion.div
                  variants={container()}
                  initial="hidden"
                  animate="show"
                  className=""
                >
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 700: 1, 780: 2, 1440: 3 }}
                  >
                    <Masonry gutter="2rem">
                      {pollsFetched.map((poll, index) => (
                        
                          <PollCard 
                            data={poll} 
                            showDetailButton  
                            userInfo={userInfo}
                          /> 
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                </motion.div>
              ) : (
                <div className="w-full h-[10vh] my-12 flex items-center justify-center">
                <span className="text-center text-primary-light text-xl font-bold antialiased">
                  How about creating some exciting polls...
                </span>
              </div>
              )}

              
            {showShowMore ? (
            <div className="loadmore text-center mt-4 mb-4"> 
                  <Button  
                    variant="filled"
                    title="Show More" 
                    onClick={() => loadMore()}
                  />
            </div>
            ) : (
              <div className="loadmore text-center mt-4 mb-4"> 
                  <Button  
                    variant="outlined"
                    title="Create Poll +" 
                    onClick={() => navigate('/create-poll')}
                  />
            </div>
            )}
            </div>
            {isPollLoading && (
              <Oval
                height={80}
                width={80}
                color="#DB171E"
                wrapperStyle={{}}
                wrapperclassName=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#EE585D"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            )}
          </div>
        )}
      {logoutConfirmationPopup && (
        <UniversalPopup handleClose={() => setLogoutConfirmationPopup(false)}>
          <div className="flex flex-col w-full items-center justify-around space-y-8 mb-3">
            <div className="flex flex-col items-center"> 
              <div className="text-primary-light">
                Are you leaving ?
              </div>
              <div className="mt-3">We will miss you!</div>
            </div>
            <div className="w-full flex justify-center space-x-6 ">
              <Button
                variant="outlined"
                title="Logout"
                onClick={handleLogout}
              />
              <Button
                title="Stay signed in"
                onClick={() => setLogoutConfirmationPopup(false)}
              />
            </div>
          </div>
        </UniversalPopup>
      )}
    </div>
  );
};

export default ArchivePage;
