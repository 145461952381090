import React, { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  LinkedInLoginButton
} from "react-social-login-buttons";
import { toast } from "react-toastify";
import { LoginSocialFacebook, LoginSocialGoogle, LoginSocialLinkedin } from "reactjs-social-login"; 
import "../../../../styles/popup.css";
import { UserContext } from "../../../App";
import AuthServiceInstance from "../../../services/authService";
import ManualSignInForm from "./ManualSignInForm";
import ManualSignUpForm from "./ManualSignUpForm";
import ResetPasswordModal from "../authentication/ResetPassword";
import { TailSpin } from "react-loader-spinner";
import Alert from "../alerts/Alert";

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const modalRoot = document.getElementById("modal");

const userDeviceInformation = navigator.userAgent;

const SignupFormModal = (props) => {
  const navigate = useNavigate();

  // const updateUserLogin = useUserLoginUpdate();
  const modalElement = document.createElement("div");
  const { handleClose, onSuccess = () => {}, onComplete = () => {} } = props;
  const [transition, setTransition] = useState("in");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [socialSignInEmailError, setsocialSignInEmailError] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailNotProvided, setIsEmailNotProvided] = useState(false);
  const [isResetPasswordMode, setIsResetPasswordMode] = useState(false);
/* https://www.googleapis.com/auth/user.birthday.read 
https://www.googleapis.com/auth/user.gender.read */
  const signUpState = {
    key: "signup",
    value: "Sign Up",
    message: "Please fill in this form to create an account!",
    action: (
      <span className="text-sm">
        Already have an account{" "}
        <span className="text-primary-light font-bold cursor-pointer hover:underline">
          Login
        </span>
      </span>
    ),
    onClickState: "signin",
  };

  const logInState = {
    key: "signin",
    value: "Sign In",
    message: "Login to manage your account",
    action: (
      <span className="text-md">
        Don’t have an account?{" "}
        <span className="text-primary-light font-bold cursor-pointer hover:underline">
          Register Now.
        </span>
      </span>
    ),
    onClickState: "signup",
  };

  const [signUpLinkToggle, setSignUpLinkToggle] = useState(logInState);

  const userLogin = useContext(UserContext);

  const fetchMe = async () => {
    const response = await AuthServiceInstance.me();
    if (response) {
      return response.data;
    }
  };

  useEffect(() => {
    modalRoot.appendChild(modalElement);
    return () => {
      modalRoot.removeChild(modalElement);
    };
  }, [modalElement]);

  const handleSignIn = (userData, source, provider = '') => {
    setIsLoading(true);  
    switch (source) {
      case "social": 

        switch (provider) {
          case 'google':
          setIsEmailVerified(userData.data?.email_verified); 
          break;
          case 'facebook':
          setIsEmailVerified(true); 
          case 'linkedin':
          setIsEmailVerified(true); 
          break; 
        } 
        setIsEmailNotProvided(userData.data?.email?.length);
        if (userData.data?.email?.length && ((userData.data?.email_verified && provider === 'google') || provider === 'facebook' || provider === 'linkedin')) {
          AuthServiceInstance.signIn(userData.data, userDeviceInformation)
            .then(async (response) => {
              if (response.success) {
                localStorage.setItem("token", `Bearer ${response.token}`);
                userLogin.logInUser(true);
                const me = await fetchMe();
                // if (me.username === null || !me.username.length) {
                //   const state = { error: true };
                //   navigate("/edit-profile", { state });
                // }
                onSuccess();
                handleClose();
              }
            })
            .catch((err) => {})
            .finally(() => {
              onComplete();
              setIsLoading(false);
            });
        } else { 
          setIsLoading(false);
          setsocialSignInEmailError(true);
          setSignUpLinkToggle(signUpState);
        }
        break;
      case "manualSignIn":
        AuthServiceInstance.manualLogin(userData, userDeviceInformation)
          .then(async (response) => { 
            if (response.success) {
              localStorage.setItem("token", `Bearer ${response.token}`);
              userLogin.logInUser(true);
              const me = await fetchMe();
              // if (me.username === null || !me.username.length) {
              //   const state = { error: true };
              //   navigate("/edit-profile", { state });
              // }
              onSuccess();
              handleClose();
            }  
          })
          .catch((err) => {})
          .finally(() => {
            onComplete();
            setIsLoading(false);
          });
        break;
      case "manualSignUp":
        AuthServiceInstance.manualSignUp(userData, userDeviceInformation)
          .then((response) => {
            if (response.success) {
              setSignUpLinkToggle(logInState);
            }
          })
          .catch((err) => {
            // updateUserLogin(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      default:
        break;
    }
  };

  const closePopup = () => {
    setTransition("out");
    handleClose();
  };

  const handleSignUpToggle = (toggledSignupState) => {
    if (toggledSignupState === "signup") {
      setSignUpLinkToggle(signUpState);
    } else {
      setSignUpLinkToggle(logInState);
    }
  };

  return createPortal(
    <div
      className={`popup z-[13] fade-${transition} ${
        transition === "in" ? "backdrop-blur-[1px]" : ""
      }`}
    >
      <div
        className={`popup_inner relative rounded-lg drop-shadow-xl fade-${transition} slide-${transition}`}
      >
        {isResetPasswordMode && (
          <button
            className="absolute top-1 left-1 close-btn"
            onClick={() => setIsResetPasswordMode(false)}
          >
            {/* <img
              className="m-2"
              src="/assets/images/back-button.png"
              alt="x close"
            /> */}
          </button>
        )}
        <button
          className="absolute top-3 right-4 close-btn text-2xl"
          onClick={() => closePopup()}
        >
          {/* <img className="m-2" src="/assets/images/close.png" alt="x close" /> */}
          <i className="las la-times"></i>
        </button>

        <div className="content flex flex-col items-center justify-center w-full pt-10">
          {!isResetPasswordMode ? (
            <>
              <div className="heading text-secondary text-2xl font-bold mb-2">
                {signUpLinkToggle.value}
              </div>
              <div className="sub-heading text-secondary text-sm mb-2 color-[#262D53]">
                {signUpLinkToggle.message}
              </div>
              {errorMessage && (
                <div className="w-full my-4 p-4 border-[0.02rem] border-solid border-[#DC3545] bg-[#FFDDDD] flex items-center">
                  <i className="las la-exclamation-circle la-lg text-[#DC3545] mr-1" />
                  <span className="text-[#DC3545]">
                    Something Went Wrong. 
                  </span>
                </div>
              )}

              {socialSignInEmailError && (
                <div className="flex flex-col items-center w-full">
                  {!isEmailNotProvided && (
                  <div className="w-full my-4 p-4 border-[0.02rem] border-solid border-[#FFA500] bg-[#fac79849] flex items-center">
                    <i className="las la-exclamation-circle la-lg text-[#FFAA00] mr-1" />
                    <span className="text-[#FFA500] sm:text-sm text-xs">
                      We haven't recieved your email, you can sign up here
                      instead.
                    </span>
                  </div>
                  )}
                  {!isEmailVerified && (
                    // <Alert message="The email you are using to log in is not verified on the social media platform.!" />
                    <div className="w-full my-4 p-4 border-[0.02rem] border-solid border-[#FFA500] bg-[#fac79849] flex items-center">
                    <i className="las la-exclamation-circle la-lg text-[#FFAA00] mr-1" />
                    <span className="text-[#FFA500] sm:text-sm text-xs">
                    The email you are using to log in is not verified on the social media platform.!
                    </span>
                  </div>
                  )}
                </div>
              )}

              {!isLoading ? (
                <div className="content flex flex-col items-center justify-end w-full relative min-h-max"> 
                  {!socialSignInEmailError && (
                    <div className="flex flex-row w-full pb-2 pt-2 md:px-20 sm:px-12 xs:px-8 px-2  z-[11] justify-center social_network">
                      <LoginSocialGoogle
                      className="mr-4 bg-[#d5d8de] rounded-lg p-[4px] cursor-pointer" 
                        scope={
                          "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
                        }
                        client_id={googleClientId}
                        onResolve={(response) => {
                          handleSignIn(response, "social", "google");
                          setErrorMessage(false);
                        }}
                        onLoginStart={() => setIsLoading(true)}
                        onReject={(error) => {
                          setErrorMessage(true);
                          setTimeout(() => {
                            setErrorMessage(false);
                          }, 3000);
                          setIsLoading(false);
                          toast.error("Failed To Login With Google", {
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            theme: "colored",
                          });
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
<path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
</svg>
                      </LoginSocialGoogle>
                      <LoginSocialFacebook
                        appId={facebookAppId}
                        fieldsProfile={"email"}
                        fields={"email"}
                        scope={"email"}
                        className="bg-[#d5d8de] rounded-lg p-[4px] cursor-pointer"
                        onResolve={(response) => {
                          handleSignIn(response, "social", "facebook");
                          setErrorMessage(false);
                        }}
                        onLoginStart={() => setIsLoading(true)}
                        onReject={(error) => {
                          setErrorMessage(true);
                          setTimeout(() => {
                            setErrorMessage(false);
                          }, 3000);
                          setIsLoading(false);
                          toast.error("Failed To Login With Facebook", {
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            theme: "colored",
                          });
                        }}
                      >
                      <svg xmlns="http://www.w3.org/2000/svg" data-name="Ebene 1" height="30" width="30" viewBox="0 0 1024 1024" id="facebook-logo-2019"><path fill="#1877f2" d="M1024,512C1024,229.23016,794.76978,0,512,0S0,229.23016,0,512c0,255.554,187.231,467.37012,432,505.77777V660H302V512H432V399.2C432,270.87982,508.43854,200,625.38922,200,681.40765,200,740,210,740,210V336H675.43713C611.83508,336,592,375.46667,592,415.95728V512H734L711.3,660H592v357.77777C836.769,979.37012,1024,767.554,1024,512Z"></path><path fill="#fff" d="M711.3,660,734,512H592V415.95728C592,375.46667,611.83508,336,675.43713,336H740V210s-58.59235-10-114.61078-10C508.43854,200,432,270.87982,432,399.2V512H302V660H432v357.77777a517.39619,517.39619,0,0,0,160,0V660Z"></path></svg>
                      </LoginSocialFacebook>
                      {/* <LoginSocialLinkedin
            isOnlyGetToken
            client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
            client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}
            redirect_uri={window.location.origin + '/callback/linkedin'} 
            scope="email"
            onResolve={(response) => {
              handleSignIn(response, "social", "linkedin");
              setErrorMessage(false);
            }}
            onLoginStart={() => setIsLoading(true)}
            onReject={(error) => {
              setErrorMessage(true);
              setTimeout(() => {
                setErrorMessage(false);
              }, 3000);
              setIsLoading(false);
              toast.error("Failed To Login With LinkedIn", {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                theme: "colored",
              });
            }}
          >
            <LinkedInLoginButton text=""/>
          </LoginSocialLinkedin> */}
                    </div>
                  )}
                  {!socialSignInEmailError && (
                    <span className="bg-lightgrey text-darkgrey rounded-full  p-1 z-[12]">
                      OR
                    </span>
                  )}
                  <div className="flex flex-col items-center w-full md:px-12 sm:px-6 xs:px-8 px-2 pb-10 rounded-b-lg z-[12] border border-red-600">
                    {signUpLinkToggle.key === "signin" ? (
                      <ManualSignInForm
                        handleSignIn={handleSignIn}
                        setIsResetPasswordMode={setIsResetPasswordMode}
                      />
                    ) : (
                      <ManualSignUpForm handleSignIn={handleSignIn} />
                    )}
                    <span
                      className="mt-3 text-sm"
                      onClick={() =>
                        handleSignUpToggle(signUpLinkToggle.onClickState)
                      }
                    >
                      {signUpLinkToggle.action}
                    </span>
                  </div>
                  
                </div>
              ) : (
                <div className="max-h-max p-12 rounded-b-lg h-full w-full flex flex-col space-y-4 items-center justify-center bg-lightgrey">
                  <TailSpin
                    height="80"
                    width="80"
                    color="#EE585D"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="p-12 pt-4 w-full">
              <ResetPasswordModal
                handleSignUpClick={() => {
                  setIsResetPasswordMode(false);
                  setSignUpLinkToggle(signUpState);
                }}
                onFinish={() => closePopup()}
              />
            </div>
          )}
        </div>
      </div>
    </div>,
    modalRoot
  );
};

export default SignupFormModal;
