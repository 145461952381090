import React from "react";

const Chip = ({ children }) => {
  return (
    <div className="chip flex flex-col items-center rounded-full bg-lightgrey sm:py-2 py-1 sm:px-4 px-2 sm:text-base text-sm">
      {children}
    </div>
  );
};

export default Chip;
