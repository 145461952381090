import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import AuthServiceInstance from "../../services/authService";
import Button from "../components/buttons/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UserContext } from "../../App";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [params, setParams] = useState({});
  const { showSignupPopup } = useContext(UserContext);

  const validationSchema = yup.object().shape({
    password: yup.string().required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const resetPassowrdApiCall = async (id, token, password) => {
    const response = await AuthServiceInstance.resetPassword(
      id,
      token,
      password
    );
    if (response) {
      showSignupPopup(true);
      navigate("/");
    }
  };

  const handleShowPassword = (state) => {
    if (state === "show") {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const token = searchParams.get("token");

    setParams({ id, token });
  }, [location.search]);

  const onPasswordReset = (newPassword) => {
    resetPassowrdApiCall(params.id, params.token, newPassword.password);
  };

  return (
    <div
      className="h-[90vh] w-full flex flex-col items-center justify-center bg-lightgrey"
      style={{
        background:
          "linear-gradient(0deg,  rgb(238, 88, 93) 0%, rgb(238, 88, 93) 0%)",
      }}
    >
      {true ? (
        <motion.div
          // whileHover={{ scale: 1.25 }}
          className="bg-lightgrey md:w-1/2 w-full rounded-full shadow-2xl shadow-black h-1/2 flex flex-col items-center space-y-8 justify-center "
        >
          {/* <span className="text-primary-dark text-2xl">Reset Password</span> */}

          <form
            onSubmit={handleSubmit(onPasswordReset)}
            className="fields flex flex-col items-center space-y-4 md:w-1/2 w-2/3"
          >
            <div className="relative w-full">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="New Password"
                {...register("password")}
                required
                style={{ overflowY: "hidden", resize: "none" }}
                className="h-13 py-3 w-full border-b-[0.09rem] border-solid border-darkgrey focus:border-[#0d6efd] ease-in duration-200 outline-none bg-transparent"
              />
              {/* <i className="las la-eye la-lg text-[#4BB543] " /> */}

              {showPassword ? (
                <i
                  className="las la-eye-slash la-lg absolute top-4 right-0 z-[12] cursor-pointer"
                  onClick={() => handleShowPassword("hide")}
                />
              ) : (
                <i
                  className="las la-eye la-lg absolute top-4 right-0 z-[12] cursor-pointer"
                  onClick={() => handleShowPassword("show")}
                />
              )}
              {errors.password && (
                <div className="error-text text-primary-dark sm:mt-2 text-xs">
                  {errors.password.message}
                </div>
              )}
            </div>

            <div className="relative w-full">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Confirm Password"
                {...register("confirmPassword")}
                required
                style={{ overflowY: "hidden", resize: "none" }}
                className="h-13 py-3 w-full border-b-[0.09rem] border-solid border-darkgrey focus:border-[#0d6efd] ease-in duration-200 outline-none bg-transparent"
              />

              {showPassword ? (
                <button onClick={() => handleShowPassword("hide")}>
                  <i className="las la-eye-slash la-lg absolute top-4 right-0 z-[12] cursor-pointer" />
                </button>
              ) : (
                <i
                  className="las la-eye la-lg absolute top-4 right-0 z-[12] cursor-pointer"
                  onClick={() => handleShowPassword("show")}
                />
              )}
              {errors.confirmPassword && (
                <div className="error-text text-primary-dark sm:mt-2 text-xs">
                  {errors.confirmPassword.message}
                </div>
              )}
            </div>
            {/* <button type="submit"></button> */}
            <Button type="submit" title="Reset Password" />
          </form>
        </motion.div>
      ) : (
        <div
          className="h-[90vh] w-full flex flex-col items-center justify-center bg-lightgrey"
          style={{
            background:
              "linear-gradient(0deg,  rgb(238, 88, 93) 0%, rgb(238, 88, 93) 0%)",
          }}
        >
          <motion.div
            whileHover={{ scale: 1.25 }}
            className="bg-lightgrey md:w-1/2 w-full rounded-full shadow-2xl shadow-black h-1/2 flex flex-col space-y-6 items-center justify-center "
          >
            <img
              src="/assets/images/broken.png"
              alt="Broken"
              className="text-9xl font-bold text-primary-light drop-shadow-lg"
            />

            <span className="text-primary-dark text-2xl">Broken Link</span>
            <Button
              title="Go back to Home"
              variant="filled"
              onClick={() => navigate("/")}
            />
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default ResetPasswordPage;
