import React, { useEffect } from "react";
import { toBlob } from "html-to-image"; 
import UploadServiceInstance from "../../../services/uploadServices";
import PollServiceInstance from "../../../services/pollService";  
const DisplayCard = ({ pollData }) => {
  useEffect(() => {    
    console.log(pollData)
    if(pollData?._id && !pollData?.image) 
    generatePollImage(pollData?._id); 
  }, [pollData]); 
   

  const generatePollImage = async (pollId) => {
    var element = document.getElementById('display-poll');
    console.log(element)
    toBlob(element) 
      .then((blob) => { 
        const fileName = 'image.png'; // Provide a filename for the File
        const imageFile = new File([blob], fileName, { type: 'image/png' }); // Specify the MIME  
      UploadServiceInstance.uploadFile(imageFile, 'poll').then((response) => {
        if(response.url) {
          PollServiceInstance.updatePoll(pollId, response.url)
          .then((response) => {
            console.log(response);
          });
        } 
      });
      });
  };

  return ( 
    <div>  
    { pollData.poll.length && (
    
    <div className={ `w-[${pollData?.poll?.length * 12}px] ` + "min-w-[200px] max-w-[500px] p-[20px] bg-white h-auto"} id="display-poll">
    <div className="">
          <div className="question text-sm font-semibold text-[#535353] word-break">
            {pollData?.poll} 
          </div> 
          <div className="word-break options mt-4 flex flex-col items-start w-full">
              
                {pollData?.options.map((option) => (
                  <button
                    className="text-sm py-1 px-2 my-[0.25rem] hover:bg-primary-light w-full outline outline-[1px] rounded-sm outline-primary-light active:bg-darkgrey hover:outline-white text-primary-light hover:text-white font-semibold hover:font-semibold ease-in duration-100" 
                  >
                    {option.option}
                  </button>
                ))} 
          </div>
        </div>
        </div> 
        )}
        </div>
  );
};

export default DisplayCard;
