import { toast } from "react-toastify";
import axiosInstance from "./axiosConfig"; 
class AuthService {
  signupPopupCall = (userAgent) => {   

    const result = new Promise((resolve, reject) => { 
      axiosInstance.get("https://api.ipify.org/?format=json")
        .then((response) => { 
        const IP = response.data.ip; 
        new Promise((resolve, reject) => { 
          axiosInstance 
            .post("/api/user/signup-popup-call", {
              ip: IP,
              user_device_info: userAgent
            })
            .then((response) => {
            }); 
        }); 

        }); 
    }); 
      
  };
  
  signIn = (userData, userDeviceInformation) => {   
    return new Promise((resolve, reject) => { 
      axiosInstance
        .post("/api/user/login", {
          user_device_info: userDeviceInformation,
          ...userData,
        })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => { 

          reject(error);
        });
    });
  };

  resendVerificationEmail = (userData) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/user/resend-verification-email", { 
          ...userData,
        })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  manualLogin = (userData, userDeviceInformation) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/user/signin", {
          user_device_info: userDeviceInformation,
          ...userData,
        })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {

          toast.error(error.response.data.message, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });

          if(error.response.data.resend_verify_email)  {  

            AuthServiceInstance.resendVerificationEmail({email: userData.email})
            .then(async (response) => {
              if (response.success) {  
                toast.success(response.message, {
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  theme: "colored",
                });
                // onSuccess();
                // handleClose();
              }
            })
            .catch((err) => {})
            .finally(() => {
              // onComplete();
              // setIsLoading(false);
            });
          }
          
          reject(error);
        });
    });
  };

  manualSignUp = (userData, userDeviceInformation) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/user/signup", {
          user_device_info: userDeviceInformation,
          ...userData,
        })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);

            toast.success(response.data.message, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  me = () => {
    if(localStorage.getItem("token")?.length) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/user/me")
        .then((response) => {
          if (response.data.success) {
            localStorage.setItem('userInfo',JSON.stringify(response.data.data));
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  };

  usernameValidity = (username) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/user/checkUserNameAvailability/${username}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  forgotPassword = (email) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/user/forgot-password", { email })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
            toast.success("We have sent a link to reset your password to your email.", {
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
          } else {
            toast.error("Something went wrong!", {
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  verifyEmail = (id, token) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/user/verify-signup-email", { id, token })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };
  verifyNewsLetterEmail = (id, token) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/user/subs-nwl-verifyemail", { id, token })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  resetPassword = (id, token, newPassword) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/user/reset-password", {
          id,
          token,
          password: newPassword,
        })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
            toast.success(response.data.message, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };
}

const AuthServiceInstance = new AuthService();
export default AuthServiceInstance;
