export const container = () => { 
  return {
    hidden: { opacity: 1, scale: 0 },
    show: {
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
        duration: 0.5,
      },
      opacity: 1,
      scale: 1,
    },
  };
};
 
