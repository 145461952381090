import React from "react";

const Alert = ({ message, severity = "info" }) => {
  switch (severity) {
    case "success":
      return (
        <div className="w-full my-4 p-4 border-[0.02rem] border-solid border-[#4BB543] bg-[#4BB54330] flex items-center rounded">
          <i className="lar la-check-circle la-lg text-[#4BB543] mr-1" />
          <span className="text-[#4BB543]">{message}</span>
        </div>
      );
    case "error":
      return (
        <div className="w-full my-4 p-4 border-[0.02rem] border-solid border-[#FF3C00] bg-[#FF3C0030] flex items-center rounded">
          <i className="las la-exclamation-triangle la-lg text-[#FF3C00] mr-1" />
          <span className="text-[#FF3C00]">{message}</span>
        </div>
      );
    case "warning":
      return (
        <div className="w-full my-4 p-4 border-[0.02rem] border-solid border-[#EF9A5F] bg-[#EF9A5F30] flex items-center rounded">
          <i className="las la-exclamation-circle la-lg text-[#EF9A5F] mr-1" />
          <span className="text-[#EF9A5F]">{message}</span>
        </div>
      );

    case "info":
    default:
      return (
        <div className="w-full my-4 p-4 border-[0.02rem] border-solid border-[#3F95E2] bg-[#3F95E230] flex items-center rounded">
          <i className="las la-info-circle la-lg text-[#3F95E2] mr-1" />
          <span className="text-[#3F95E2] w-full">{message}</span>
        </div>
      );
  }
};

export default Alert;
