import { motion } from "framer-motion";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import { UserContext } from "../../../App";
import PollServiceInstance from "../../../services/pollService";
import { capitalizeFirstLetter } from "../../../utils/formatters";
import PollHelperInstance from "../../../utils/pollHelper";
import Button from "../buttons/Button";
import UniversalPopup from "../modals/UniversalPopup";
import ShareCard from "./shareCard";
import UserDetails from "../custom/UserDetails";
import { toast } from "react-toastify"; 
import MButton from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
 

const server_url = process.env.REACT_APP_CDN_URL;
const s3Url = process.env.REACT_APP_S3_CDN_URL;
 



const PollCard = ({
  data: pollData,
  index: index,
  embedIn: embedIn,
  userHasVoted = () => {},
  userInfo,
  reFetchMe = () => {},
  ...props
}) => {
  const {
    state: { isLoggedIn },
    showSignupPopup,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const pollLink = `${server_url}/poll/${pollData.slug}`;
  const pollText = `${pollData.poll}`;
  const [menuOpen, setMenuOpen] = useState(true);  
  const [deleteOpen, setDeleteOpen] = useState(false);  
  const [eachPoll, setEachPoll] = useState(pollData);  
  const [pollLikesCount, setPollLikesCount] = useState(pollData?.likes);
  const [voteCount, setVoteCount] = useState(0);
  const [userVotedThisPoll, setUserVotedThisPoll] = useState(pollData?.voted);
  const [isOwnerOfPoll, setIsOwnerOfPoll] = useState(false);
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [shareCard, setShareCard] = useState(false);
  const [showComponent, setShowComponent] = useState(true);
  const [user] = useState(pollData?.user); 
  const [showUserDetailsPopup, setShowUserDetailsPopup] = useState(false); 
  const [deletePollConfirmationPopup, setDeletePollConfirmationPopup] = useState(false);

  const handleUserProfileNavigate = () => {
    navigate(`/profile/${eachPoll?.user?._id}`);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

 

  const [optionsPercentages, setOptionsPercentages] = useState([ 
    ...eachPoll.options.map ( (option) => {
      return {
        percent: 0,
        votes: option?.votes
      }
    })
  ]);


  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeletePoll = (index) => {
    PollServiceInstance.deletePoll(eachPoll?._id).then(
      (response) => {
        setDeletePollConfirmationPopup(false); 
    if (response.success) {
      toast.success(response.message, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: "colored",
      });
       
      if(matchPath({ path: "/poll/:slug" }, location.pathname)) { 
        navigate('/');
      } else { 
        setShowComponent(false);
      }
 
    } else {
      toast.error("unable to delete poll", {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: "colored",
      });  
    }
  }) 
  .catch();;
    
  };

  useEffect(() => {
    if (eachPoll?.liked) {
      setLikedPost(eachPoll?.liked);
    } 
  }, [pollData, userVotedThisPoll]);
   
  useEffect(() => { 
    setTimeout(() => {
      if(!menuOpen) {
        setMenuOpen(false)
        setDeleteOpen(true) 
      }
    }, 100);
      
  }, [menuOpen]);

   


  useEffect(() => { 
    setTimeout(() => { 
      if (userInfo?._id === user?._id) {
        setIsOwnerOfPoll(true);
        if(matchPath({ path: "/profile/:id" }, location.pathname)) { 
          setIsProfilePage(true);
        }  
      }
    }, 100);
  }, [userInfo]);

  const [likedPost, setLikedPost] = useState(false);
  const handlePostLike = () => {
    // setLikedPost((prev) => !prev);
    if (isLoggedIn) {
      if (!likedPost) {
        PollServiceInstance.likePoll(eachPoll?._id)
          .then((response) => {
            if (response.success) { 
              setLikedPost(true);
              setPollLikesCount((prev) => prev + 1); 
            } else {
              setLikedPost(false);
            }
          })
          .catch();
      } else {
        PollServiceInstance.disLikePoll(eachPoll?._id)
          .then((response) => {
            if (response.success) {
              setLikedPost(false);
              setPollLikesCount((prev) => prev - 1);
            } else {
              setLikedPost(true);
            }
          })
          .catch();
      }
    } else {
      showSignupPopup(true);
    }
  };

  const handleVote = (votedOptionId,index) => {
    if (isLoggedIn) {  
      const userInfoJson = localStorage.getItem('userInfo');
      const userInfo = JSON.parse(userInfoJson);
      
        PollServiceInstance.votePoll(eachPoll?._id, votedOptionId)
          .then((response) => {
            if (response.success) {
              if (response.data._id === eachPoll?._id) {
                response.data.voted = {
                  option : votedOptionId
                }
                optionsPercentages[index].votes++; 
                setEachPoll(response.data);
                setUserVotedThisPoll(true);
                setVoteCount((prev) => prev + 1);
                userHasVoted(true); 
              }
            } else {
              setVoteCount((prev) => prev - 1);
              setEachPoll(pollData);
            }
          })
          .catch();
      
    } else {
      showSignupPopup(true);
    }
  }; 

  useEffect(() => { 
  
    
    if (eachPoll?.totalVote) {
      setVoteCount(eachPoll?.totalVote);
    } else {
      let voteCount = 0;
      eachPoll.options.forEach((eachOption) => {
        voteCount += eachOption.votes;
      });
      eachPoll.totalVote = voteCount;
      setVoteCount(voteCount);
    }


    if(typeof eachPoll?.voted === 'string') { 
      eachPoll.voted = {
        option: eachPoll.voted
      }; 
    }
     
    if(eachPoll?.voted?.option) { 
      const interval = setInterval(() => {    
          
        setOptionsPercentages((prevData) => 
        prevData.map((item) => ({
          ...item,
          percent: item.percent < (Math.round((item.votes / eachPoll?.totalVote) * 100)) ? item.percent + 1 : (Math.round((item.votes / eachPoll?.totalVote) * 100)),   
        })));  
      }, 30); 

      setTimeout(() => {
        clearInterval(interval); 
      }, 10000); 
  
      return () => {
        clearInterval(interval); // Clear the interval when the component unmounts
      };
    }
  
  }, [eachPoll]);

  const renderTimeRemaining = () => {
    const createdAt = eachPoll.createdAt;
    const now = moment();
    const durationInDays = eachPoll.duration;

    const end = moment(createdAt).add(durationInDays, "days");
    const timeRemaining = moment.duration(end.diff(now));

    let formattedTimeRemaining = "";

    if (now.isBefore(end)) {
      formattedTimeRemaining = `${timeRemaining.humanize()}`;
    } else {
      formattedTimeRemaining = "Never";
    }

    return formattedTimeRemaining;
  };

  return (
    <>
    {!showComponent ? (<></>) : ( 
    <div 
      className={`main flex flex-col w-full bg-white rounded-2xl max-h-max relative card_shadow`}
    >
      <div className="head flex justify-between w-full px-4 xl:px-8 py-4 z-[2]">
        <div className="flex relative">
          <div className="md:mr-5 mr-3 cursor-pointer flex items-center justify-center">
            <img
              className="rounded-full sm:w-[4rem] sm:h-[4rem] w-[3.8rem] h-[3.8rem] border-white border-0 hover:shadow-md hover:border-2 hover:border-solid hover:border-primary-light ease-in-out duration-300 card_profile_img"
              // src={settingConfig.BASE_STORAGE_CONTAINER_URL + user.picture}
              src={
                user?.picture
                  ? `${s3Url}/${user?.picture}`
                  : "/assets/images/dummyUser.png"
              }
              alt="avatar"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/images/dummyUser.png";
              }}
              onClick={handleUserProfileNavigate}
            />
          </div>
          <div className="flex flex-col space-y-1 ">
            {user?.username?.length && (
              <div
                className="text-base sm:text-lg md:text-xl font-medium text-primary-dark subpixel-antialiased cursor-pointer"
                onClick={handleUserProfileNavigate}
              >
                @<span className="underline">{user?.username}</span>
              </div>
            )}

            {!user?.username?.length && (
              <div
                className="pl-[2px] text-base sm:text-lg md:text-xl font-medium text-primary-dark subpixel-antialiased cursor-pointer"
                onClick={handleUserProfileNavigate}
              >
                <span className="">Anonymous</span>
              </div>
            )}
            
            <div className="text-xs font-medium ml-1">
            Expires: {capitalizeFirstLetter(renderTimeRemaining())} 
            </div>
            
            <div className="text-xs font-medium ml-1">
              Posted At: {moment(eachPoll?.createdAt).format("ll")}
            </div> 

            

            
          </div>
        </div>
        <div className="absolute right-4 top-4 flex-col items-center">
          {props?.showDetailButton ? ( 
            <div className="inline">
              <Button
                variant="filled"
                title="Insight"
                onClick={() => navigate(`/poll/${eachPoll?.slug}`)}
              />
            </div>

          ) : ( 
            <div className="inline">
              <Button
                variant="filled"
                title="Analytics"
                onClick={() => window.scroll({top: 620, behavior: 'smooth' })}
              />
            </div>

          )}
          {isProfilePage && isOwnerOfPoll && (   
          <div className="inline">
          <MButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
          padding: '10px 0px 0px 0px',
          minWidth: '34px',
          verticalAlign: 'top'
        }}
      >
      <i className="las la-ellipsis-v text-primary-dark text-2xl"> 
      </i>    
      </MButton>
      <Menu
      sx={{  
        '.MuiMenu-list': {
          padding: '0px'
        },
      }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={ () => { 
          setDeletePollConfirmationPopup(true);
          handleClose();
        }}
        className="text-xs font-medium"
        sx={{    
            margin: '0px !important',
            paddingLeft: '3px',
            paddingRight: '3px',
            minHeight: '34px',
            fontSize: '0.75rem',
            fontWeight: '500',
            lineHeight: '1rem',
            fontFamily: '"Poppins", sans-serif',
            verticalAlign: 'middle'   
        }} 
        >
        <i className="las la-trash text-primary-dark text-1xl"> 
        </i>   
        Delete
        </MenuItem> 
      </Menu>
    </div>
          )}
        </div>
      </div>
      <div className="poll-content xl:px-6 px-4 py-4 h-full w-full flex flex-col z-[2]">
        <div className="flex flex-col items-start w-full">
          <div className="question text-base font-medium text-[#535353] word-break">
            {eachPoll?.poll} 
          </div> 
          <div className="word-break options mt-4 flex flex-col items-start w-full">
            
            {userVotedThisPoll ? (
              <>
                {eachPoll?.options.map((option,index) => {
                  const countedVoteOnOption = (option?.votes / voteCount) * 100;
                  return (
                    <div
                      key={option._id}
                      className={`relative container w-full flex items-center bg-[#ECEEF4] ${
                        option._id === eachPoll?.voted?.option
                          ? "outline outline-[1.2px]"
                          : ""
                      } ${option.option.length > 44 ? "h-[3.5rem] md:h-[2.65rem]" : "h-[2.65rem]"} mt-1`}
                    >
                      <motion.div
                        className="absolute h-3/4 bg-white shadow lg:max-w-[97.5%] md:max-w-[98%] max-w-[95%] ml-2 border-l-[3px] border-solid border-primary-light" 
                        style={{
                          width: `${optionsPercentages[index]?.percent}%`,  
                        }}  
                      />   
                      <div className="skills w-full flex items-center justify-between z-[2] px-2"> 
                            <span className="text-[14px] md:text-[16px] pl-4 pr-3">{option.option}  </span>
                            <span className="float-right mr-2 ms-atuo">{Math.round(optionsPercentages[index]?.percent)}%</span> 
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {eachPoll?.options.map((option,index) => (
                  <button
                    className="py-2 px-2 my-[0.25rem] hover:bg-primary-light w-full outline outline-[1px] rounded-sm outline-primary-light active:bg-darkgrey hover:outline-white text-primary-light hover:text-white hover:font-semibold ease-in duration-100"
                    key={option._id}
                    onClick={() => handleVote(option._id,index)}
                  >
                    {option.option}
                  </button>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="actions mt-3 flex items-center justify-between">
          <div className="flex w-1/1 items-center">
            <div className="flex flex-col items-center cursor-pointer">
              {likedPost ? (
                <div className="flex items-center mx-2">
                  <img
                    className="active:shadow-lg mr-[3px] mb-[3px] w-[16px] h-[16px]" 
                    onClick={handlePostLike}
                    src="/assets/images/thumb-fill.svg"
                    alt="like"
                  />
                  <div className="sm:text-base text-sm">
                  {pollLikesCount > 0 ? pollLikesCount  + PollHelperInstance.fakeNumberOfLikesOnPollCard(eachPoll?.createdAt) : ''} {pollLikesCount > 1 ? 'likes' : pollLikesCount > 0 ? 'like' : 'like'} 
                  </div>  
                </div>
              ) : (
                <div className="flex items-center mr-3"> 
                  <i 
                  onClick={handlePostLike}
                  className="las la-thumbs-up text-2xl text-primary-light"></i>  
                    <div className="sm:text-base text-sm">
                    {pollLikesCount > 0 ? pollLikesCount  + PollHelperInstance.fakeNumberOfLikesOnPollCard(eachPoll?.createdAt) : ''} {pollLikesCount > 1 ? 'likes' : pollLikesCount > 0 ? 'like' : 'like'} 
                    </div> 
                </div>
              )}
              
            </div>
            {voteCount > 0 && ( 
              <div 
              onClick={() => navigate(`/poll/${eachPoll?.slug}`)}
              className="flex items-center mx-2 cursor-pointer">
                {/* <img 
                  className=""
                  src="/assets/images/post-vote.svg"
                  alt="like"
                /> */}
                <i className="las la-poll text-2xl text-primary-light"></i>

                {userVotedThisPoll ? (
                <>  
                <span className="sm:text-base text-sm ml-1">
                {/* {voteCount} {voteCount === 1 ? "vote" : "votes"} */} 
                {/* Increased votes count by + 12 everyday */}
                {voteCount + PollHelperInstance.fakeNumberOfvotesOnPollCard(eachPoll?.createdAt) } {voteCount === 1 ? "vote" : "votes"}
                </span> 
                </>
                ) : (
                <>
                <span className="sm:text-base text-sm ml-1">
                {/* {voteCount} votes  */}
                {/* Increased votes count by + 12 everyday */}
                {voteCount + PollHelperInstance.fakeNumberOfvotesOnPollCard(eachPoll?.createdAt) } votes 
                </span>
                </>
                )}
              </div>
            )}
          </div>
          <div className="">
            <Button
              variant="filled"
              title="Share"
              onClick={() => setShareCard((prev) => !prev)}
            />
          </div>
        </div>
      </div>
      {shareCard && (
        <UniversalPopup
          title="Share this poll"
          handleClose={() => setShareCard(false)}
        >
          <ShareCard
            shareLink={pollLink}
            pollDataForImage={pollData}
            shareText={pollText}
            closeUploadDialog={() => setShareCard(false)}
          />
        </UniversalPopup>
      )}
      

      {deletePollConfirmationPopup && (
        <UniversalPopup handleClose={() => setDeletePollConfirmationPopup(false)}>
          <div className="flex flex-col w-full items-center justify-around space-y-8 mb-3">
            <div className="flex flex-col items-center"> 
              <div className="text-primary-light">
                Are you sure you want to delete this poll ?
              </div>
              <div className="mt-3 word-break">{eachPoll?.poll}</div>
            </div>
            <div className="w-full flex justify-center space-x-6 ">
              <Button
                variant="outlined"
                title="Delete"
                onClick={() => handleDeletePoll(index)}
              />
              <Button
                title="Cancel"
                onClick={() => setDeletePollConfirmationPopup(false)}
              />
            </div>
          </div>
        </UniversalPopup>
      )}
    </div>  
      )}
      </>
  );
};

export default PollCard;
