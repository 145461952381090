import React, { useEffect, useState } from "react";

const Button = (props) => {
  const { title, onClick, width = "", height = "", variant } = props;
  const filled =
    "p-2 md:px-6 px-6 bg-primary-light text-light text-white  hover:bg-primary-dark ease-in duration-300";
  const outlined =
    "p-2 md:px-6 px-4 bg-white border-[0.08rem] border-solid text-primary-dark  border-primary-dark hover:text-white hover:bg-primary-dark ease-in duration-300";
  const outlinedIcon =
    "py-2 md:px-3 px-0 bg-white border-[0.08rem] border-solid text-primary-dark  border-primary-dark hover:text-white hover:bg-primary-dark ease-in duration-300";
  const [buttonVariant, setButtonVariant] = useState(filled);

  useEffect(() => {
    switch (variant) {
      case "filled":
        setButtonVariant(filled);
        break;
      case "outlined":
        setButtonVariant(outlined);
        break;
      case "outlinedIcon":
        setButtonVariant(outlinedIcon);
        break;
      default:
        break;
    }
  }, [variant]);

  return (
    <>
      {!props?.disabled ? (
        <button
          type={props.type || "button"}
          style={{ width: width, height: height }}
          className={`${buttonVariant} rounded-full my-2 p-2 drop-shadow-lg active:bg-darkgrey  sm:text-base text-xs`}
          onClick={onClick}
          disabled={props?.disabled || false}
        >
          {title}
        </button>
      ) : (
        <button
          type={props.type || "button"}
          style={{ width: width, height: height }}
          className="bg-darkgrey text-lightgrey hover:bg-darkgrey border-0 rounded-full p-2 px-4 m-2  drop-shadow-lg active:bg-darkgrey sm:text-base text-xs"
          onClick={onClick}
          disabled={props?.disabled || false}
        >
          {title}
        </button>
      )}
    </>
  );
};

export default Button;
