import React from "react";
import "../../../../styles/loader.css";

const Loader = () => {
  return (
    <div>
    <div style={{height: '250px'}}> 
    </div>
    <div className="max-h-[47vh] h-full w-full flex flex-col space-y-4 items-center justify-center bg-lightgrey">
      <div className="loader shadow-lg" />
      <p className="text-2xl font-bold text-primary-light drop-shadow-lg flex justify-center w-full">
        Loading...
      </p>
    </div>
    <div style={{height: '250px'}}> 
    </div>
    </div>
  );
};

export default Loader;
