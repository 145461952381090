import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import NewsletterServiceInstance from "../../../services/newsletterService";
import { emailSchema } from "../../validations/EmailValidation";

const NewsletterSection = () => {
  const [alert, setAlert] = useState({});

  const defaultValues = {
    email: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(emailSchema),
  });

  const subscribeToNewsletter = ({ email }) => {
    NewsletterServiceInstance.subscribeToNewsletter(email)
      .then((response) => {
        if (response.success) {
          reset(defaultValues);
          setAlert({ success: true, message: "Subscribed To Newsletter" });
        } else {
          setAlert({ success: true, message: response.message });
        }
      })
      .catch((err) => {
        setAlert({ success: false, message: "Cannot Subscribe to Newsletter" });
      });
  };

  useEffect(() => {
    let alertTimeout;
    if (!!alert) {
      alertTimeout = setTimeout(() => {
        setAlert({});
      }, 8000);
    }
    return () => {
      clearTimeout(alertTimeout);
    };
  }, [alert]);

  return (
    <div
      className="newsletter-bg bg-primary-light flex flex-col items-center justify-center text-white w-full min-h-[40vh] md:p-10 p-4"
      
    >
      <div className="heading md:text-4xl text-xl font-bold my-2 text-white">
      Newsletter
      </div>
      <div className="description my-2 md:text-base text-sm text-center">
      Stay informed and be part of the voice that matters! Subscribe to our newsletter for exclusive poll results, trending topics, and valuable insights delivered right to your inbox.
      </div>
      <form
        noValidate
        onSubmit={handleSubmit(subscribeToNewsletter)}
        className="w-full flex flex-col items-center"
      >
        <div className="md:w-full mt-4 flex items-center justify-center relative min-[278px]:text-[12px] min-[350px]:text-[16px]">
          <input
            {...register("email")}
            autoComplete="off"
            placeholder="Email Address"
            className="email-input lg:w-3/12 md:w-1/2 outline-none border-[0.08rem] p-2 md:p-4 text-primary-dark rounded font-sans subpixel-antialiased color:black background-color:white rounded-l-lg"
            style={{ color: "black", backgroundColor: "white"}}
          />
          {errors.email && <span className="text-[white] text-sm absolute -bottom-7 pb-1">{errors?.email?.message}</span>}
          <button 
          type="Submit"
          className={   
            "bg-[#181A29] p-2 md:p-4 md:px-6 text-light text-white rounded-r-lg ease-in duration-300"
          }
        >
          SUBSCRIBE
        </button>
        </div>
        
        {/* {alert?.message?.length && (
          <div className="w-full border-solid flex flex-col items-center rounded">
            <div className="flex items-center">
              <i className="las la-check-circle la-lg text-[#4BB543] mr-1" />
              <span className="text-[#4BB543]">{alert.message}</span>
            </div>
          </div>
        )} */}
      </form>
    </div>
  );
};

export default NewsletterSection;
