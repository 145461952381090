import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard"; 
import DisplayCard from "./displayCard"; 
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
} from "react-share";
import Button from "../buttons/Button";
import { toast } from "react-toastify";

const facebookAppId = process.env.FACEBOOK_APP_ID;

const iconStyle = "rounded-lg";
const iconSize = "3rem";

const ShareCard = ({ shareLink, shareText, pollDataForImage }) => { 
 
 
  const handleWhatsappShare = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      `${shareLink}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div>
      {pollDataForImage?.poll?.length && ( 
          <DisplayCard pollData={pollDataForImage}/> 
      )}
    <div className="flex flex-col items-center rounded-xl h-full  border-primary-light border-solid w-full px-8">
      <div className="share-icons flex justify-between w-full">
        
       
      
        <LinkedinShareButton
          url={shareLink}
          title={shareText}
          children={
            <LinkedinIcon
              size={iconSize}
              className={`${iconStyle} hover:border-primary-light hover:border-4 hover:border-solid ease-in duration-100 hover:shadow-xl`}
            />
          }
        /> 
        <button onClick={handleWhatsappShare}>
          <WhatsappIcon
            size={iconSize}
            className={`${iconStyle} hover:border-primary-light hover:border-4 hover:border-solid ease-in duration-100 hover:shadow-xl`}
          />
        </button>
        
        <TwitterShareButton
          url={shareLink}
          title={shareText}
          children={
            <TwitterIcon
              size={iconSize}
              className={`${iconStyle} hover:border-primary-light hover:border-4 hover:border-solid ease-in duration-100 hover:shadow-xl`}
            />
          }
        />
        
       
            {/* <CopyToClipboard
          text={shareLink}
          onCopy={() => {
            toast.info("Link Copied", {
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              // closeButton: false,
              theme: "colored",
            });
          }}
        >
          <Button className="" variant="outlined" title="copy" />
        </CopyToClipboard> */}
         

        
      </div>
      <div className="w-full flex flex-col items-center">
        <input
        readOnly={'readonly'}
          value={shareLink}
          className="opacity-0 flex items-center px-2 border-[0.1rem] rounded-xl h-[2.5rem] border-solid border-primary-light w-full focus:border-primary-dark"
        /> 
      </div>  
    </div>
    </div>
  );
};

export default ShareCard;
