import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import AuthServiceInstance from "../../../services/authService";
import { useState } from "react";
import { Oval } from "react-loader-spinner";

const container = {
  hidden: {
    opacity: 0,
    top: 0,
    transition: {
      staggerChildren: 1,
      delayChildren: 1,
      // ease: "easeOut",
      duration: 0.5,
    },
  },
  show: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.3,
      // ease: "easeOut",
      duration: 0.2,
    },
    opacity: 1,
    top: 59,
  },
};

const item = {
  hidden: { opacity: 0, y: 0 },
  show: { opacity: 1, y: 1 },
};

const ResetPasswordModal = ({ handleSignUpClick, onFinish }) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setIsButtonLoading(true);
    const response = await AuthServiceInstance.forgotPassword(data.email)
    .then((response) => { 
      // setIsButtonLoading(false);
    })
    .catch(() => { 
      // setIsButtonLoading(false);
    }); 
    onFinish();
  };

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="flex flex-col items-center space-y-8"
    >
      <motion.div
        variants={item}
        className="flex flex-col items-center space-y-4"
      >
        {/* <img src="/assets/images/password.png" alt="Forgot Password" /> */}
        <i className="las la-unlock text-6xl"></i>
        <div className="heading text-secondary text-2xl font-semibold ">
          Forgot Password ?
        </div>
        <div className="sub-heading text-secondary text-xs mb-8 text-center">
          Enter the email address associated with your account and we'll send
          you a link to reset your password.
        </div>
      </motion.div>
      <motion.div className="w-full" variants={item}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col items-center space-y-4 px-2"
        >
          <div className="w-full">
            <input
              disabled={isButtonLoading}
              placeholder="Email"
              {...register("email")}
              type="text"
              required
              style={{ overflowY: "hidden", resize: "none" }}
              className="h-13 py-3 w-full border-b-[0.09rem] border-solid border-darkgrey focus:border-[#0d6efd] ease-in duration-200 outline-none bg-transparent text-primary-dark text-center"
            />

            {errors.email && (
              <div className="error-text text-primary-dark sm:mt-2 text-xs">
                {errors.email.message}
              </div>
            )}
          </div>

          <div className="action-btns w-full">
            <button
              disabled={isButtonLoading}
              type="submit"
              className="w-full px-4 py-3 rounded-full bg-primary-light font-bold text-white hover:bg-primary-dark ease-in duration-300 shadow-lg active:bg-darkgrey"
            >
              {isButtonLoading ? (
                <Oval
                  height={25}
                  width={25}
                  color="#DB171E"
                  wrapperStyle={{}}
                  wrapperclassName=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#EE585D"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "Send Reset Link"
              )}
            </button>
          </div>
        </form>
      </motion.div>
      <motion.div className="w-full flex flex-col items-center" variants={item}>
        <span className="text-sm">
          Don’t have an account?{" "}
          <span
            className="text-primary-light font-bold cursor-pointer hover:underline"
            onClick={handleSignUpClick}
          >
            Register Now.
          </span>
        </span>
      </motion.div>
    </motion.div>
  );
};

export default ResetPasswordModal;
