import axiosInstance from "./axiosConfig";
import { toast } from "react-toastify";

class UploadServiceWorker {
  uploadFile = (file_data, dir = 'default') => {
    return new Promise((resolve, reject) => {
      const fileUpload = new FormData();
      fileUpload.append(`image`, file_data);
      fileUpload.append(`dir`, dir);
      axiosInstance
        .post(`/api/user/upload-image/${dir}`, fileUpload, {
          headers: { "Content-Type": "multipart/form-data" },
        }) 
        .then((response) => {
          if (response.data.success) { 
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => { 
          reject(error);
        });
    });
  };

  uploadProfileImage = (url) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/user/uploadProfileImage`, { url })
        .then((response) => {
          if (response.data.success) {
            toast.success("Image Uploaded Successfully", {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
            resolve(response.data);
          } else {
            toast.error("Failed To Upload Image", {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Upload Image", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };
}

const UploadServiceInstance = new UploadServiceWorker();
export default UploadServiceInstance;
