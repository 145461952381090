import axiosInstance from "./axiosConfig";
import { toast } from "react-toastify";
class NewsletterService {
  subscribeToNewsletter = (email) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/user/subscribetonewsletter`, { email })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
            toast.success(response.data.message, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };
}
const NewsletterServiceInstance = new NewsletterService();
export default NewsletterServiceInstance;
