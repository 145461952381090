import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router";
import Button from "../components/buttons/Button";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div
      className="h-[90vh] w-full flex flex-col items-center justify-center bg-lightgrey"
      style={{
        background:
          "linear-gradient(0deg,  rgb(238, 88, 93) 0%, rgb(238, 88, 93) 0%)",
      }}
    >
      <motion.div
        whileHover={{ scale: 1.25 }}
        className="bg-lightgrey md:w-1/2 w-full rounded-full shadow-2xl shadow-black h-1/2 flex flex-col items-center justify-center "
      >
        <p className="text-9xl font-bold text-primary-light drop-shadow-lg">
          404
        </p>
        <span className="text-primary-dark text-2xl">Page Not Found</span>
        <Button
          title="Go back to Home"
          variant="filled"
          onClick={() => navigate("/")}
        />
      </motion.div>
    </div>
  );
};

export default NotFound;
