import { createContext, useEffect, useReducer, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import PrivateRoutes from "./main/authentication/PrivateRoutes";
import NavBar from "./main/components/navbar/navBar";
import ArchivePage from "./main/pages/authorProfile";
import CreatePollPage from "./main/pages/createPollPage";
import HomePage from "./main/pages/homePage";
import NotFound from "./main/pages/notFound";
import PollDetailsPage from "./main/pages/pollDetailsPage";
import ProfilePage from "./main/pages/profilePage";
import ResetPasswordPage from "./main/pages/resetPasswordPage";
import { MainReducer } from "./main/reducers/reducer";
import VerifyEmailPage from "./main/pages/verifyEmailPage";
import TermsAndConditions from "./main/pages/TermsAndConditions";
import PrivacyPolicy from "./main/pages/PrivacyPolicy";
import DataDeletionPolicy from "./main/pages/DataDeletionPolicy";
import VerifyNewsLetterEmailPage from "./main/pages/verifyNewsLetterEmailPage";
import AuthServiceInstance from "./services/authService";
 
export const UserContext = createContext();

const initialState = {
  isLoggedIn: false,
  showSignUp: false,
  profileStatus: null,
  showResetPassWord: false,
};
 

function App() {
  const [state, dispatch] = useReducer(MainReducer, initialState);
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);

  const value = {
    state,
    showSignupPopup: (flag) => {   
      if(flag)
      AuthServiceInstance.signupPopupCall(navigator.userAgent);
      dispatch({ type: "showSignupPopup", payload: flag });
    },
    logInUser: (isLogin) => {
      dispatch({ type: "login", payload: isLogin });
    },
    profileDataStatus: (status) => {
      dispatch({ type: "profile_status", payload: status });
    },
    showResetPassWordPopup: (flag) => {
      dispatch({ type: "showResetPasswordPopup", payload: flag });
    },
  };

  useEffect(() => {
    function handleScroll() {
      setIsScrolledToTop(window.scrollY < 100);
    }

    window.addEventListener("scroll", handleScroll);

    if (localStorage.getItem("token")?.length) {
      value.logInUser(true);
    } else value.logInUser(false);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <UserContext.Provider value={value}>
      <BrowserRouter>
        <div className="App">
          <ToastContainer
            position="bottom-right"
            hideProgressBar
            autoClose={3000}
            closeOnClick
            closeButton={false}
          />
          <NavBar />
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoutes>
                  <HomePage />
                </PrivateRoutes>
              }
            />

            <Route
              path="/profile/:id"
              element={
                <PrivateRoutes>
                  <ArchivePage />
                </PrivateRoutes>
              }
            />
            <Route
              path="/edit-profile"
              element={
                // <PrivateRoutes>

                <ProfilePage />
                // </PrivateRoutes>
              }
            />
            <Route
              path="/create-poll"
              element={
                <PrivateRoutes>
                  <CreatePollPage />
                </PrivateRoutes>
              }
            />
            <Route
              path="/poll/:slug"
              element={
                <PrivateRoutes>
                  <PollDetailsPage key={0}/>
                </PrivateRoutes>
              }
            />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/subs-nwl-verifyemail"
              element={<VerifyNewsLetterEmailPage />}
            />
            <Route path="/verify-signup-email" element={<VerifyEmailPage />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/data-deletion-policy"
              element={<DataDeletionPolicy />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>

          
        </div>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
