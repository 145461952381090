import axiosInstance from "./axiosConfig";
import { toast } from "react-toastify";

class DiscussionService {
  getDiscussions = (pollId) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/poll/getDiscussion/${pollId}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Get Discussions", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  deleteDiscussion = (discussionId) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`/api/poll/deleteDiscussion/${discussionId}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Delete This Comment", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  addDiscussion = (pollId, comment) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/poll/addDiscussion/${pollId}`, { comment })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Add This Comment", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  voteDiscussion = (discussionId, voteType) => { 
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/poll/voteDiscussion/${discussionId}/${voteType}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Vote For This Discussion", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  unVoteDiscussion = (discussionId, voteType) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/poll/unVoteDiscussion/${discussionId}/${voteType}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Vote For This Discussion", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };
}
const DiscussionServiceInstance = new DiscussionService();
export default DiscussionServiceInstance;
