import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import DiscussionServiceInstance from "../../../services/discussionService";

const s3Url = process.env.REACT_APP_S3_CDN_URL;

const CommentCard = ({ data, ...props }) => {
  const navigate = useNavigate();

  const {
    comment = "",
    createdAt = "",
    votedType = "",
    user: { _id, username, firstName, picture },
  } = (data?.user?._id ? data : {
    comment : "",
    createdAt : "",
    votedType : "",
    user: {_id: '', username: '', firstName: '', picture: ''}
    });

  const pollId = props?.pollId;

  const [discussionVotes, setDiscussionVotes] = useState({
    votedType: "",
    liked: 0,
    disliked: 0
  });
 
  useEffect(() => {     
    if (!data?.votedType) { 
      setDiscussionVotes((prev) => ({ ...prev, votedType: '', liked: data?.liked, disliked: data?.disliked }));
    } 
    else if (data?.votedType === 'liked') {
      setDiscussionVotes((prev) => ({ ...prev, votedType: 'liked', liked: data?.liked, disliked: data?.disliked }));
    }
    else if (data?.votedType === 'disliked') { 
      setDiscussionVotes((prev) => ({ ...prev, votedType: 'disliked', liked: data?.liked, disliked: data?.disliked }));
    }  
  }, [data]);

 

  const handleUserProfileNavigate = () => {
    navigate(`/profile/${_id}`);
  };

  const handleCommentActions = (actionType, discussionId) => {
    if (props?.isLoggedIn) { 
      console.log(actionType)
      switch (actionType) {
        case "like":
          DiscussionServiceInstance.voteDiscussion(discussionId, "liked")
          .then((response)=> {
            const data = response.data; 
            props.refreshDiscussion();
          }); 
          break;
        case "unlike":
          DiscussionServiceInstance.unVoteDiscussion(discussionId, "liked")
          .then((response)=> {
            const data = response.data; 
            props.refreshDiscussion();
          }); 
          break;
        case "dislike":
          DiscussionServiceInstance.voteDiscussion(discussionId, "disliked")
          .then((response)=> {
            const data = response.data; 
            props.refreshDiscussion();
          }); 
          break;
        case "undislike":
          DiscussionServiceInstance.unVoteDiscussion(discussionId, "disliked")
          .then((response)=> {
            const data = response.data; 
            props.refreshDiscussion();
          }); 
          break;
        default:
          break;
      }
    } else {
      props?.showSignupPopup(true);
    }
  };

  return (
    <div className="w-full rounded-lg">
      <div className="w-full rounded-t-lg py-2">
        <div className="header flex justify-between sm:pl-4 pl-3 sm:pr-8 pr-3 w-full ">
          <div className="user-info flex justify-end">
            <img
            onClick={() => navigate(`/profile/${data?.user?._id}`)}
              src={
                picture ? `${s3Url}/${picture}` : "/assets/images/dummyUser.png"
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/images/dummyUser.png";
              }}    
              alt="avatar"
              className="rounded-full w-[3rem] h-[3rem] ease-in-out duration-300 cursor-pointer"
            />
            <div className="ml-4 flex flex-col">   
              {username?.length && ( 
               
              <p className="text-[14px]" 
              >
                <span onClick={() => navigate(`/profile/${data?.user?._id}`)} className=" cursor-pointer text-primary-dark font-medium">@{username}</span> &nbsp; 
                <span className="text-[10px]">{moment(data.createdAt).fromNow()}</span>
              </p> 
            )} 

            {!username?.length && (
              <p
              
            >
              <span className="text-primary-dark font-medium mr-3">Anonymous</span> <span className="text-[10px]">{moment(data.createdAt).fromNow()}</span>
            </p> 
            )}


 
              <div className="text-[16px]" dangerouslySetInnerHTML={{ __html: comment.replace(
              /(https?:\/\/[^\s]+)/g,
              (href) => `<a class="underline text-[#4287f5]" href="${href}">${href.replace(window.location.origin + '/poll/', '')}</a>`
              ) }} />
              <div className=" flex items-center space-x-4 mt-2">
            {discussionVotes?.votedType !== "liked" ? (
              <div className="flex items-center text-xs space-x-1"> 
                <i  
                onClick={() => handleCommentActions("like", data._id)} 
                className="las cursor-pointer la-thumbs-up text-2xl text-primary-light"></i>  
                <span>{discussionVotes.liked > 0 ? discussionVotes.liked : ''}</span>
              </div>
            ) : (
              <
                div className="flex items-center text-xs space-x-1"> 
                    <img
                    className="cursor-pointer active:shadow-lg mr-[3px] mb-[3px] w-[18px] h-[18px]" 
                    onClick={() => handleCommentActions("unlike", data._id)}
                    src="/assets/images/thumb-fill.svg"
                    alt="like"
                  /> 
                <span>{discussionVotes.liked > 0 ? discussionVotes.liked : ''}</span>
              </div>
            )}
            {discussionVotes?.votedType !== "disliked" ? (
              <div className="flex items-center text-xs space-x-1"> 
                <i 
                onClick={() => handleCommentActions("dislike", data._id)}
                className="las cursor-pointer la-thumbs-down text-2xl text-primary-light"></i> 
                <span>{discussionVotes.disliked > 0 ? discussionVotes.disliked : ''}</span>
              </div>
            ) : (
              <div className="flex items-center text-xs space-x-1"> 
                    <img className="rotate-180 cursor-pointer active:shadow-lg mr-[3px] mb-[3px] w-[18px] h-[18px]"
                    onClick={() => handleCommentActions("undislike", data._id)}
                    src="/assets/images/thumb-fill.svg"
                    alt="like"
                  />

                <span>{discussionVotes.disliked > 0 ? discussionVotes.disliked : ''}</span>
              </div>
            )}
            {/* <a href={'#'} className="flex items-center space-x-1 text-sm text-primary-light">Reply <i class="las la-angle-double-right"></i></a> */}
          </div>
            </div>
          </div> 
        </div>
        {/* <div className="header flex justify-between pl-[80px] sm:pr-8 pr-3 pt-5 w-full ">
          <div className="user-info flex justify-end">
               <img
              src={
                picture ? `${s3Url}/${picture}` : "/assets/images/dummyUser.png"
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/images/dummyUser.png";
              }}
              alt="avatar"
              className="rounded-full w-[2rem] h-[2rem] ease-in-out duration-300"
            />

          <div className="ml-4 flex flex-col"> 
          <p ><span className="text-primary-dark font-medium mr-3">@test</span> <span className="text-[14px]">22 Minutes ago</span></p> 
          <div class="text-[16px]">Hello Suresh</div>
          <div className=" flex items-center space-x-4 mt-2"><div className="flex items-center text-xs space-x-1"><i class="las cursor-pointer la-thumbs-up text-2xl text-primary-light"></i><span>1</span></div><div className="flex items-center text-xs space-x-1"><i className="las cursor-pointer la-thumbs-down text-2xl text-primary-light"></i><span></span></div><a href="#" className="flex items-center space-x-1 text-sm text-primary-light">Reply <i className="las la-angle-double-right"></i></a></div>
          </div>
          
          </div></div> */}
      </div>
      <div className="content w-full px-2 ">
        <div className="comment-message md:ml-6 text-[#535360] text-md">
          
        </div>
         
      </div>
    </div>
  );
};

export default CommentCard;
