import axiosInstance from "./axiosConfig";
import { toast } from "react-toastify";

class ChartService {
  genderChart = (pollId, optionId) => {
    return new Promise((resolve, reject) => {
      let endPoint = `poll=${pollId}`;
      if (optionId) endPoint = `poll=${pollId}&option=${optionId}`;
      axiosInstance
        .get(`/api/poll/getPollGenderAnalytics?${endPoint}`, {
          poll: pollId,
          option: optionId,
        })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Get Gender Data", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  ageChart = (pollId, optionId) => {
    return new Promise((resolve, reject) => {
      let endPoint = `poll=${pollId}`;
      if (optionId) endPoint = `poll=${pollId}&option=${optionId}`;
      axiosInstance
        .get(`/api/poll/getPollAgeAnalytics?${endPoint}`, {
          poll: pollId,
          option: optionId,
        })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Get Age Data", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };
}

const ChartServiceInstance = new ChartService();
export default ChartServiceInstance;
