import axiosInstance from "./axiosConfig";
import { toast } from "react-toastify";
class PollServiceWorker {
  getAllPolls = (pageSize, page, categoryId) => {
    return new Promise((resolve, reject) => {
      let url = `/api/poll/get?pageSize=${pageSize}&page=${page}`;
      url = categoryId ? url + `&categoryId=${categoryId}` : url;
      axiosInstance
        .get(url)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Get Polls", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };
  getPollsByUser = (userId, pageSize, page) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/poll/get?user=${userId}&pageSize=${pageSize}&page=${page}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Get Polls", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  getPollById = (pollId) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/poll/get?id=${pollId}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Get Polls", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  getPollBySlug = (slug) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/poll/get?slug=${slug}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Get Polls", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };
  
  getPollDuration = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/poll/getPollDuration`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To load Poll Duration Fields", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  searchPoll = (keyword) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/poll/search?keyword=${keyword}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Search", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  deletePoll = (pollId) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`/api/poll/delete/${pollId}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Delete This Poll", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  createPoll = (body) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/poll/create`, body)
        .then((response) => {
          if (response.data.success) {
            toast.success("It's Published", {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
            resolve(response.data);
          } else {
            toast.error("Failed To Create This Poll", {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: "colored",
            });
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Create This Poll", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  getUserPoll = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/poll/get/user`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  votePoll = (pollId, optionId) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/poll/vote/${pollId}`, { id: optionId })
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Vote For This Poll", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  likePoll = (pollId) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/poll/like/${pollId}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Like This Poll", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  disLikePoll = (pollId) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/poll/unlike/${pollId}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed To Dislike This Poll", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  getPollsWithMyVote = (userId, pageSize, page) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/poll/getMyVotedPolls?id=${userId}&pageSize=${pageSize}&page=${page}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed to get polls with my votes", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };

  getVoteCount = (userId) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/poll/getPollCount?id=${userId}`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed to get polls with my votes", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };
  
  getMostVotedPolls = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/poll/getMostVotedPolls`)
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Failed to get polls most voted polls", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          });
          reject(error);
        });
    });
  };
 
 
  updatePoll = (id, image) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/poll/updatePoll`, {id: id, image: image})
        .then((response) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          // toast.error("Failed to get polls with my votes", {
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   theme: "colored",
          // });
          reject(error);
        });
    });
  };
}

const PollServiceInstance = new PollServiceWorker();
export default PollServiceInstance;
