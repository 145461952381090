import React, { useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router";
import { UserContext } from "../../../App";
import AuthServiceInstance from "../../../services/authService";
import UploadServiceInstance from "../../../services/uploadServices";
import UserProfileInstance from "../../../services/userProfileService";
import Loader from "../Loader/loader";
import Button from "../buttons/Button";
import { toast } from "react-toastify";
 
const s3Url = process.env.REACT_APP_S3_CDN_URL;

const UserDetails = ({ ...props }) => {
  const popupReason = props.popupReason;
  const demographyRequired = props.demographyRequired;
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("user");
  const { state: locationState } = useLocation();
  const inputRef = useRef();
  const [profileData, setProfileData] = useState();
  const [userNameError, setUserNameError] = useState("");
  const [existingFields, setExistingFields] = useState({});
  const [selectedGender, setSelectedGender] = useState("");
  const [usernameNotPresentError, setUsernameNotPresentError] = useState(false);

  const [alert, setAlert] = useState({ state: false, message: "" });
  const [disableUpdateButton, setDisableUpdateButton] = useState(true);

  const handleChange = (event) => {
    setDisableUpdateButton(false);
    const { name, value } = event.target;
    if (name === "gender") {
      setSelectedGender(value);
    }
    if (name === "username") { 
      setProfileData((prev) => ({ ...prev, [name]: value.toLowerCase() }));
      return;
    }
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (locationState?.alertMessage && !profileData?.username?.length) {
      setUsernameNotPresentError(locationState?.error);
      setAlert({ state: true, message: locationState?.alertMessage });
    }
  }, [locationState]);

  useEffect(() => {
    if (profileData?.username?.length && !existingFields?.username) {
      const validUsernameCheck = setTimeout(() => {
        usernameAvailabilityCheck(profileData?.username);
      }, 700);
      return () => clearTimeout(validUsernameCheck);
    }
  }, [profileData?.username]);

  const usernameAvailabilityCheck = (usernameToBeVerified) => {
    AuthServiceInstance.usernameValidity(usernameToBeVerified)
      .then((response) => {
        if (response.success) {
          setUserNameError("");
          setUsernameNotPresentError(false);

          if (!usernameToBeVerified.length) {
            setUserNameError("");
          }
          if(!usernameToBeVerified.match(/^[A-Za-z0-9]*$/)) {
            setDisableUpdateButton(true);
            setUserNameError("You can use only letters and numbers");
          }
        } else {
          setUserNameError("Username Already Taken");
        }
      })
      .catch((error) => {
        setUserNameError("Username Already Taken");
      });
  };

  const handleDoBChange = (dobValue) => {
    setProfileData((prev) => ({ ...prev, dob: dobValue }));
    setDisableUpdateButton(false);
  };

  const [profileDataLoading, setProfileDataLoading] = useState(true);

  const fetchMe = () => {
    AuthServiceInstance.me()
      .then((response) => {
        if (response) {
          setProfileData(response.data);
          response.data.gender && setSelectedGender(response.data.gender);
          Object.keys(response.data).forEach((key) => {
            if (response.data[key] !== null && !!response.data[key].length) {
              setExistingFields((prev) => ({ ...prev, [key]: true }));
            }
          });
        }
      })
      .catch((err) => {})
      .finally(() => setProfileDataLoading(false));
  };

  const onChooseImg = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    console.log("event.target.files[0] :", event.target.files[0]);
    if (event.target.files && event.target.files.length > 0) {
      UploadServiceInstance.uploadFile(event.target.files[0], 'profile')
        .then((response) => {
          console.log(response)
          if (response.success) {
            UploadServiceInstance.uploadProfileImage(response.url).then(
              (response) => {
                if (response.success) {
                  fetchMe();
                  userContext.profileDataStatus("updated");
                }
              }
            );
          }
        },
        (err) => {
          toast.error("Failed To Upload File", {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "colored",
          })
        }
        ) 
        .catch( 
        );
    }
  };

  useEffect(() => {
    fetchMe();
  }, []);

  const handleUpdate = async () => {
    const finalResponse = {};
    if (
      profileData?.username?.length &&
      !userNameError &&
      !existingFields.username
    ) {
      finalResponse.username = true;
      const response = await UserProfileInstance.updateUsername(
        profileData?.username
      );
      if (response.success) {
        finalResponse.username = false;
        AuthServiceInstance.me().then(() => {
          props.onVotePopupComplete();
        })
      }
    }
    if (profileData?.dob && !existingFields.dob) {
      finalResponse.dob = true;
      const response = await UserProfileInstance.updateDob(profileData.dob);
      setAlert({ state: false, message: "" });
      if (response.success) {
        finalResponse.dob = false;
        AuthServiceInstance.me().then(() => { 
          props.onVotePopupComplete();
        })
      }
    }
    if (
      profileData?.gender &&
      profileData?.gender.length &&
      !existingFields.gender
    ) {
      finalResponse.gender = true;
      const response = await UserProfileInstance.updateGender(
        profileData.gender
      );
      setAlert({ state: false, message: "" });
      if (response.success) {
        setDisableUpdateButton(true);
        finalResponse.gender = false;
        
        AuthServiceInstance.me().then(() => { 
          props.onVotePopupComplete();
        })
      }
    }

    const notUpdatedAllSuccefuly = Object.values(finalResponse).some(
      (resp) => resp
    );
    if (!notUpdatedAllSuccefuly) {
      fetchMe();
      setDisableUpdateButton(true);
    }
     
  };

  const CustomDatePickerHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    changeYear,
    changeMonth,
  }) => {
    const yearOptions = Array.from({ length: 101 }, (_, i) => (
      <option key={i} value={date.getFullYear() - 50 + i}>
        {date.getFullYear() - 50 + i}
      </option>
    ));
    return (
      <div className="flex flex-col item-center bg-primary-light">
        <div className="flex justify-center space-x-20">
          <div className="mr-2">
            <select
              className="p-4 bg-primary-light text-white shadow-lg"
              value={date.getFullYear()}
              onChange={({ target }) => changeYear(target.value)}
            >
              {yearOptions}
            </select>
          </div>
          <div className="ml-2">
            <select
              className="p-4 bg-primary-light text-white shadow-lg"
              value={date.getMonth()}
              onChange={({ target }) => changeMonth(target.value)}
            >
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i} value={i}>
                  {new Date(date.getFullYear(), i).toLocaleString("default", {
                    month: "long",
                  })}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const CustomDatePickerInput = ({ value, onClick, readOnly }) => (
    <div className="py-3 px-3 text-base w-full word-break mt-4 border border-solid border-[#ced4da] focus:border-[#0d6efd] ease-in duration-200 outline-none rounded-lg">
      <img src="/assets/images/datepicker.png" alt="Date" className="absolute top-7" />
      <input
        readOnly={readOnly}
        type="text"
        value={value}
        onClick={onClick}
        className="text-base w-full ease-in duration-200 outline-none pl-8"
      />
    </div>
  );

  return (
    <>
      {profileDataLoading ? (
        <Loader />
      ) : (
        <div className="relative"> 
          {alert.state && (
            <div className="w-full my-4 pb-6 border-[0.02rem] border-solid border-[#DC3545] bg-[#FFDDDD] flex items-center">
              <i className="las la-exclamation-circle la-lg text-[#DC3545] mr-1" />
              <span className="text-[#DC3545] sm:text-sm text-xs">
                {alert.message}
              </span>
            </div>
          )}
          {usernameNotPresentError && (
            <div className="w-full my-4 p-4 border-[0.02rem] border-solid border-[#DC3545] bg-[#FFDDDD] flex">
              <i className="las la-exclamation-circle la-lg text-[#DC3545] mr-1" />
              <span className="text-[#DC3545]">
                Please provide a username to your account.
              </span>
            </div>
          )}
          
          { !Boolean(popupReason) && (
          <div className="profile-pic flex sm:flex-row flex-col items-center justify-center mb-10">
            <img
              alt="Profile"
              src={
                profileData?.picture
                  ? `${s3Url}/${profileData.picture}`
                  : "/assets/images/dummyUser.png"
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/images/dummyUser.png";
              }}
              className="rounded-full sm:w-[5rem] sm:h-[5rem] w-[3.8rem] h-[3.8rem] border-white border-0 hover:shadow-lg hover:border-2 hover:border-solid hover:border-primary-light ease-in-out duration-300"
              onClick={() => onChooseImg()}
            />
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
              style={{ display: "none", backgroundColor: "transparent" }}
            /> 
          </div>
          )}
          { popupReason !== "vote" && (
          <div className="username my-0 w-full">
              <label className="text-primary-light font-semibold">
                Username
              </label>
              <div className="flex flex-col items-center">
                <div className="flex items-end w-full relative">
                  <span className="font-bold text-darkgrey absolute left-3 top-7">@</span>
                  <input
                    readOnly={existingFields?.username}
                    name="username"
                    value={profileData?.username || ""}
                    onChange={handleChange}
                    className={`py-3 px-3 text-base w-full word-break mt-4 border border-solid border-[#ced4da] focus:border-[#0d6efd] ease-in duration-200 outline-none rounded-lg pl-9 ${
                      userNameError.length
                        ? "border-[#FF0000] focus:border-[#FF0000]"
                        : "focus:border-[#00FF00]"
                    }`}
                  />
                </div>
              </div>
              <span className="text-sm text-darkgrey ml-6 mt-1">
                {userNameError.length > 0 && userNameError}
              </span>
            </div>
            )}
            { !Boolean(popupReason) && (
            <div className="email my-0">
              <label className="text-primary-light font-semibold">Email</label>
              <input
                readOnly={existingFields?.email}
                name="email"
                value={profileData?.email || ""}
                onChange={handleChange}
                className="py-3 px-3 text-base w-full word-break mt-4 border border-solid border-[#ced4da] focus:border-[#0d6efd] ease-in duration-200 outline-none rounded-lg"
              />
            </div>
            )}
          <div className="flex sm:flex-row flex-col items-start sm:justify-between sm:space-x-10 space-x-0 sm:space-y-0 space-y-6 my-6 w-full">
            { popupReason !== 'create' && demographyRequired === 'age' && (
            <div className="age w-full z-1 flex flex-col items-start">
              <label className="text-primary-light font-semibold">
                Birth Year
              </label>
              {/* <DatePicker
                className="w-full datepicker"
                readOnly={existingFields?.dob}
                selected={profileData?.dob ? new Date(profileData?.dob) : ""}
                onChange={(value, event) => {
                  handleDoBChange(value);
                }}
                dateFormat="dd-MMM-yyyy"
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={101}
                showMonthDropdown
                dropdownMode="select"
                renderCustomHeader={CustomDatePickerHeader}
                maxDate={new Date()}
                popperModifiers={[
                  {
                    name: "preventOverflow",
                    options: {
                      rootBoundary: "document",
                      tether: false,
                      altAxis: true,
                    },
                  },
                ]}
                customInput={<CustomDatePickerInput />}
              /> */}
              {/* <label className="text-primary-light font-semibold">Birth Year</label> */}
              <select
                disabled={existingFields?.dob}
                value={profileData?.dob ? new Date(profileData?.dob)?.getFullYear() : ""}
                placeholder="eg. 1998"
                name="dob"
                id="dob"
                onChange={handleChange}
                className="py-3 px-3 text-base w-full word-break mt-4 border border-solid border-[#ced4da] focus:border-[#0d6efd] ease-in duration-200 outline-none rounded-lg bg-transparent"
              >
                <option className="text-primary-light" value="">
                &ensp; &ensp;  --Birth Year--
                </option>
                <option className="text-primary-light" value="2023">&ensp; &ensp; &ensp; &ensp; 2023  &ensp;</option>
                <option className="text-primary-light" value="2022">&ensp; &ensp; &ensp; &ensp; 2022  &ensp;</option>
                <option className="text-primary-light" value="2021">&ensp; &ensp; &ensp; &ensp; 2021  &ensp;</option>
                <option className="text-primary-light" value="2020">&ensp; &ensp; &ensp; &ensp; 2020  &ensp;</option>
                <option className="text-primary-light" value="2019">&ensp; &ensp; &ensp; &ensp; 2019  &ensp;</option>
                <option className="text-primary-light" value="2018">&ensp; &ensp; &ensp; &ensp; 2018  &ensp;</option>
                <option className="text-primary-light" value="2017">&ensp; &ensp; &ensp; &ensp; 2017  &ensp;</option>
                <option className="text-primary-light" value="2016">&ensp; &ensp; &ensp; &ensp; 2016  &ensp;</option>
                <option className="text-primary-light" value="2015">&ensp; &ensp; &ensp; &ensp; 2015  &ensp;</option>
                <option className="text-primary-light" value="2014">&ensp; &ensp; &ensp; &ensp; 2014  &ensp;</option>
                <option className="text-primary-light" value="2013">&ensp; &ensp; &ensp; &ensp; 2013  &ensp;</option>
                <option className="text-primary-light" value="2012">&ensp; &ensp; &ensp; &ensp; 2012  &ensp;</option>
                <option className="text-primary-light" value="2011">&ensp; &ensp; &ensp; &ensp; 2011  &ensp;</option>
                <option className="text-primary-light" value="2010">&ensp; &ensp; &ensp; &ensp; 2010  &ensp;</option> 
                <option className="text-primary-light" value="2009">&ensp; &ensp; &ensp; &ensp; 2009  &ensp;</option>
                <option className="text-primary-light" value="2008">&ensp; &ensp; &ensp; &ensp; 2008  &ensp;</option>
                <option className="text-primary-light" value="2007">&ensp; &ensp; &ensp; &ensp; 2007  &ensp;</option>
                <option className="text-primary-light" value="2006">&ensp; &ensp; &ensp; &ensp; 2006  &ensp;</option>
                <option className="text-primary-light" value="2005">&ensp; &ensp; &ensp; &ensp; 2005  &ensp;</option>
                <option className="text-primary-light" value="2004">&ensp; &ensp; &ensp; &ensp; 2004  &ensp;</option>
                <option className="text-primary-light" value="2003">&ensp; &ensp; &ensp; &ensp; 2003  &ensp;</option>
                <option className="text-primary-light" value="2002">&ensp; &ensp; &ensp; &ensp; 2002  &ensp;</option>
                <option className="text-primary-light" value="2001">&ensp; &ensp; &ensp; &ensp; 2001  &ensp;</option>
                <option className="text-primary-light" value="2000">&ensp; &ensp; &ensp; &ensp; 2000  &ensp;</option>
                <option className="text-primary-light" value="1999">&ensp; &ensp; &ensp; &ensp; 1999  &ensp;</option>
                <option className="text-primary-light" value="1998">&ensp; &ensp; &ensp; &ensp; 1998  &ensp;</option>
                <option className="text-primary-light" value="1997">&ensp; &ensp; &ensp; &ensp; 1997  &ensp;</option>
                <option className="text-primary-light" value="1996">&ensp; &ensp; &ensp; &ensp; 1996  &ensp;</option>
                <option className="text-primary-light" value="1995">&ensp; &ensp; &ensp; &ensp; 1995  &ensp;</option>
                <option className="text-primary-light" value="1994">&ensp; &ensp; &ensp; &ensp; 1994  &ensp;</option>
                <option className="text-primary-light" value="1993">&ensp; &ensp; &ensp; &ensp; 1993  &ensp;</option>
                <option className="text-primary-light" value="1992">&ensp; &ensp; &ensp; &ensp; 1992  &ensp;</option>
                <option className="text-primary-light" value="1991">&ensp; &ensp; &ensp; &ensp; 1991  &ensp;</option>
                <option className="text-primary-light" value="1990">&ensp; &ensp; &ensp; &ensp; 1990  &ensp;</option>
                <option className="text-primary-light" value="1989">&ensp; &ensp; &ensp; &ensp; 1989  &ensp;</option>
                <option className="text-primary-light" value="1988">&ensp; &ensp; &ensp; &ensp; 1988  &ensp;</option>
              </select>
            </div>
            )}
            { popupReason !== 'create' && demographyRequired === 'gender' && (
            <div className="gender w-full">
              <label className="text-primary-light font-semibold">Gender</label>
              <select
                disabled={existingFields?.gender}
                value={selectedGender}
                placeholder="---select gender---"
                name="gender"
                id="gender"
                onChange={handleChange}
                className="py-3 px-3 text-base w-full word-break mt-4 border border-solid border-[#ced4da] focus:border-[#0d6efd] ease-in duration-200 outline-none rounded-lg bg-transparent"
              >
                <option className="text-primary-light" value="">
                  -select gender-
                </option>
                <option className="text-primary-light" value="female">
                  Female (F)
                </option>
                <option className="text-primary-light" value="male">
                  Male (M)
                </option>
                <option className="text-primary-light" value="nonbinary">
                  Nonbinary
                </option>
              </select>
              {!selectedGender.length && (
                <div className="text-xs text-darkgrey mt-1">
                  Please select you gender
                </div>
              )}
            </div>
            )}
          </div>
          <div className="mt-8 flex justify-center ">
            <div className="space-x-4">
              {!Boolean(popupReason) &&
              (<Button 
                onClick={() => navigate(`/profile/${userId}`)}
                variant="outlined"
                title="Back"  
              />)
              }
              <Button 
                disabled={disableUpdateButton}
                variant="filled"
                title="Update"
                onClick={handleUpdate}
              /> 
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetails;
