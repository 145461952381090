import React, { useEffect } from "react";
import settingsConfig from "../../services/settingConfig";

const appName = settingsConfig.APP_NAME;

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-4">Terms and Conditions</h1>
      <h2 className="text-xl mb-4">Effective Date: 1 July 2023</h2>

      <p className="mb-4">
        Welcome to {appName}. These Terms and Conditions govern your use of our
        website and services. By accessing or using our website, you agree to be
        bound by these Terms and Conditions. If you do not agree with any part
        of these terms, please refrain from using our website.
      </p>

      <h3 className="text-lg font-bold mb-2">1. Use of Our Website:</h3>

      <h4 className="text-lg font-bold mb-2">1.1 Eligibility:</h4>
      <p className="mb-2">- You can use our website at any age.</p>

      <h4 className="text-lg font-bold mb-2">1.2 User Account:</h4>
      <p className="mb-2">
        - You may need to create a user account to access certain features of
        our website. You are responsible for maintaining the confidentiality of
        your account credentials and for all activities that occur under your
        account.
      </p>

      <h4 className="text-lg font-bold mb-2">1.3 Prohibited Conduct:</h4>
      <p className="mb-4">- When using our website, you agree not to:</p>
      <ul className="list-disc ml-6 mb-4">
        <li>Violate any applicable laws or regulations</li>
        <li>Engage in any illegal, harmful, or abusive activities</li>
        <li>Interfere with the proper functioning of our website</li>
        <li>
          Upload or transmit any malicious code, viruses, or harmful materials
        </li>
        <li>
          Attempt to gain unauthorized access to our systems or the accounts of
          other users
        </li>
      </ul>

      <h3 className="text-lg font-bold mb-2">2. Intellectual Property:</h3>

      <h4 className="text-lg font-bold mb-2">2.1 Ownership:</h4>
      <p className="mb-2">
        - All content, materials, and intellectual property rights on our
        website are owned by or licensed to us. You may not use, reproduce,
        distribute, or modify any content from our website without prior written
        consent from us.
      </p>

      <h4 className="text-lg font-bold mb-2">2.2 User Content:</h4>
      <p className="mb-4">
        - By posting or submitting content on our website, you grant us a
        non-exclusive, royalty-free, perpetual, and worldwide license to use,
        display, reproduce, and distribute your content for the purpose of
        providing our services. You represent and warrant that you have the
        necessary rights to grant this license.
      </p>

      <h3 className="text-lg font-bold mb-2">3. Polls:</h3>

      <h4 className="text-lg font-bold mb-2">3.1 Creation and Distribution:</h4>
      <p className="mb-2">
        - Our website allows you to create and distribute polls and polls. You
        are solely responsible for the content and accuracy of the polls and
        polls you create.
      </p>

      <h4 className="text-lg font-bold mb-2">3.2 User Conduct:</h4>
      <p className="mb-4">
        - When using our polling features, you agree not to:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Create or distribute polls that contain illegal, harmful, or offensive
          content
        </li>
        <li>Manipulate or tamper with the results of any poll</li>
        <li>Impersonate any person or entity</li>
      </ul>

      <h4 className="text-lg font-bold mb-2">3.3 Data Privacy:</h4>
      <p className="mb-4">
        - We respect the privacy of individuals who participate in polls and
        polls. You agree to comply with all applicable data protection laws
        and regulations when collecting and handling personal information
        through our website.
      </p>

      <h3 className="text-lg font-bold mb-2">4. Limitation of Liability:</h3>

      <p className="mb-4">
        - We strive to provide accurate and reliable information on our website,
        but we do not guarantee its completeness, accuracy, or reliability. You
        use our website at your own risk, and we shall not be liable for any
        damages or losses arising from your use of our website or reliance on
        its content.
      </p>

      <h3 className="text-lg font-bold mb-2">5. Indemnification:</h3>

      <p className="mb-4">
        - You agree to indemnify and hold us harmless from any claims, damages,
        liabilities, and expenses (including legal fees) arising out of your use
        of our website, violation of these Terms and Conditions, or infringement
        of any rights of third parties.
      </p>

      <h3 className="text-lg font-bold mb-2">6. Termination:</h3>

      <p className="mb-4">
        - We may terminate or suspend your access to our website at any time and
        without prior notice if we believe you have violated these Terms and
        Conditions. Upon termination, your right to use our website will cease
        immediately.
      </p>

      <h3 className="text-lg font-bold mb-2">
        7. Changes to the Terms and Conditions:
      </h3>

      <p className="mb-4">
        - We reserve the right to update or modify these Terms and Conditions at
        any time without prior notice. Any changes will be effective upon
        posting the revised version on our website. Your continued use of our
        website after the changes constitutes your acceptance of the revised
        Terms and Conditions.
      </p>

      <h3 className="text-lg font-bold mb-2">
        8. Governing Law and Jurisdiction:
      </h3>

      <p className="mb-4">
        - These Terms and Conditions are governed by the laws of [Insert
        Jurisdiction]. Any disputes arising out of or relating to these Terms
        and Conditions shall be subject to the exclusive jurisdiction of the
        courts of [Insert Jurisdiction].
      </p>
    </div>
  );
};

export default TermsAndConditions;
